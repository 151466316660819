import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, theme, ConfigProvider } from "antd";
import { Content } from "antd/es/layout/layout";
import SideBar from "../../components/sideBar/sideBar";
import KeyInfo from "../keyInfo/keyInfo";
import FinancialSoundness from "pages/financialSoundness/financialSoundness";
import StandardSupervision from "pages/standardSupervision/standardSupervision";
import MonthlyDeposit from "pages/monthlyDeposit/monthlyDeposit";
import MonthlyDepositTrends from "pages/monthlyDepositTrends/monthlyDepositTrends";
import AlertSummary from "pages/alertSummary/alertSummary";
import BiWeeklyDeposit from "pages/biWeeklyDeposit/biWeeklyDeposit";
import BiWeeklyDepositTrends from "pages/biWeeklyDepositTrends/biWeeklyDepositTrends";
import News from "pages/news/news";
import { Box } from "grommet";
import { RootState } from "store";
import Tabs from "../../constants/tabs";
import styles from "./profileDetails.module.scss";

const ProfileDetails = () => {
  const { id, tabName } = useParams();
  const provider = useSelector((state: RootState) =>
    state.providers.providers.find(
      (provider) => provider.fields["Base Id"] === id
    )
  )?.fields;
  const isLoadingProvider = useSelector(
    (state: RootState) => state.providers.isLoading
  );

  const navigate = useNavigate();
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (!tabName) {
      navigate(`/dashboard/${id}/${Tabs.key_info}`);
    }
  }, [id, navigate, tabName]);

  const getTabContent = () => {
    switch (tabName) {
      case Tabs.key_info:
        return <KeyInfo />;
      case Tabs.news:
        return <News />;
      case Tabs.financial_soundness:
        return <FinancialSoundness />;
      case Tabs.standard_supervision:
        return <StandardSupervision />;
      case Tabs.monthly_deposit:
        return <MonthlyDeposit />;
      case Tabs.monthly_deposit_trends:
        return <MonthlyDepositTrends />;
      case Tabs.biweekly_deposit:
        return <BiWeeklyDeposit />;
      case Tabs.biweekly_deposit_trends:
        return <BiWeeklyDepositTrends />;
      case Tabs.alert_summary:
        return <AlertSummary />;
      default:
        return <KeyInfo />;
    }
  };

  const customTheme = {};

  return (
    <ConfigProvider theme={customTheme}>
      <Layout
        className={styles.root}
        style={{
          minHeight: "100vh",
        }}
      >
        {!provider && !isLoadingProvider ? (
          <Content className={styles.content}>
            <Box margin={"5px 0"} align="center">
              Provider Not Found
            </Box>
          </Content>
        ) : (
          <>
            {" "}
            <SideBar />
            <Layout>
              <Content className={styles.content}>
                <div
                  className={styles.tabContent}
                  style={{
                    padding: 24,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  {getTabContent()}
                </div>
              </Content>
            </Layout>
          </>
        )}
      </Layout>
    </ConfigProvider>
  );
};

export default ProfileDetails;
