import React from "react";
import { Typography, Row, Col } from "antd";
import { ProviderFields } from "store/providers/types";
import styles from "./auditInformation.module.scss";

const { Title, Text } = Typography;

interface AuditInformationProps {
  provider: ProviderFields;
}

const AuditInformation: React.FC<AuditInformationProps> = ({ provider }) => {
  return (
    <div className={styles.container}>
      <Title level={2}>Audit Information</Title>
      <Row gutter={[0, 0]}>
        <Col xs={24} md={8}>
          <Title level={5}>External Auditor</Title>
          <Text>{provider["External Auditor"] || "-"}</Text>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>External Submission Date</Title>
          <Text>{provider["External Audit Submission Date"] || "-"}</Text>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>External Due Date</Title>
          <Text>{provider["External Audit Due Date"] || "-"}</Text>
        </Col>
      </Row>
      <Row gutter={[0, 0]}>
        <Col xs={24} md={8}>
          <Title level={5}>Internal Auditor</Title>
          <Text>{provider["Internal Auditor"] || "-"}</Text>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>Internal Submission Date</Title>
          <Text>{provider["Internal Audit Submission Date"] || "-"}</Text>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>External Due Date</Title>
          <Text>{provider["Internal Audit Due Date"] || "-"}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default AuditInformation;
