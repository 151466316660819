import { createAsyncThunk } from "@reduxjs/toolkit";
import AirtableApiClient from "utils/airtableApiClient";
import { AlertSummaryRecord, AlertSummaryPayload } from "./types";

export const getAlertSummaryDataAction = createAsyncThunk<
  AlertSummaryPayload,
  { baseId: string },
  {}
>("alertSummary/getData", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const alertSummaryData =
      await AirtableApiClient.fetchAllRecords<AlertSummaryRecord>(
        client,
        "Alert Summary?view=Master view"
      );
    return { alertSummaryData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
