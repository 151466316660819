import React from "react";
import { Col, Row, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styles from "./overview.module.scss";
import { Box } from "grommet";

const { Title, Text } = Typography;

const TransactionOverview: React.FC = () => {
  const { data } = useSelector((state: RootState) => state.monthlyDeposit);
  const overviewData = data.filter((item) =>
    item.fields.Page.includes("Overview;")
  );
  const getValue = (key: string) =>
    overviewData.find((item) => item.fields.Key === key)?.fields.Value || "N/A";

  const ListedTokenRow = data.find(
    (record) => record.fields.Key === "Listed Token"
  );

  return (
    <div className={styles.container}>
      <Box align="end">
        <div>
          {ListedTokenRow?.fields.Value
            ? `${ListedTokenRow?.fields.Value}% of tokens are covered.`
            : " "}{" "}
          {ListedTokenRow?.fields.Value && (
            <Tooltip title={ListedTokenRow?.fields.List?.replaceAll(";", ", ")}>
              <u>List of unsupported tokens</u>
            </Tooltip>
          )}
        </div>
      </Box>
      <div>
        <Title level={2}>Summary</Title>
        <Row gutter={[0, 0]}>
          <Col xs={24} md={8}>
            <div>
              <div className={styles.textContainer}>
                <Title level={5}>VASP Deposit Volume (USD)</Title>
              </div>
              <Text>${getValue("VASP Deposit Volume").toLocaleString()}</Text>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div>
              <Title level={5}>Total Number of Users</Title>
              <Text>{getValue("Total Number of Users")}</Text>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div>
              <Title level={5}>Total No. of Wallet Addresses</Title>
              <Text>{getValue("Total Number of Wallet Addresses")}</Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TransactionOverview;
