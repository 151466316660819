import { createSlice } from "@reduxjs/toolkit";
import { getSupervisionOverviewAction } from "./supervisionOverviewActions";
import { SupervisionOverviewRecord } from "./types";

export interface ISupervisionOverviewSliceState {
  supervisionOverview: SupervisionOverviewRecord[];
  isLoading: boolean;
}

const initialState: ISupervisionOverviewSliceState = {
  supervisionOverview: [],
  isLoading: false,
};

export const supervisionOverviewSlice = createSlice({
  name: "supervisionOverview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupervisionOverviewAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSupervisionOverviewAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.supervisionOverview = action.payload.supervisionOverview;
      })
      .addCase(getSupervisionOverviewAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default supervisionOverviewSlice.reducer;
