import React from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

interface PercentageChangeProps {
  percentageChange: number | undefined;
}

const PercentageChange: React.FC<PercentageChangeProps> = ({
  percentageChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        color: percentageChange
          ? percentageChange > 0
            ? "#0db977"
            : "red"
          : "#f2f2f2",
        marginTop: 4,
      }}
    >
      {percentageChange ? (
        <div style={{ marginRight: 2 }}>
          {percentageChange > 0 ? (
            <ArrowUpOutlined style={{ color: "#0db977", fontSize: 12 }} />
          ) : (
            <ArrowDownOutlined style={{ color: "red", fontSize: 12 }} />
          )}
        </div>
      ) : null}
      {percentageChange ? `${percentageChange.toFixed(2)}%` : "-"}
    </div>
  );
};

export default PercentageChange;
