import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { RootState, useAppDispatch } from "store";
import {
  getBiWeeklyDepositDataAction,
  getBiWeeklyDepositInvolvedAddressesAction,
} from "store/biWeeklyDeposit/biWeeklyDepositActions";
import RiskProfileAndBreakdown from "./riskProfileAndBreakdown";
import TransactionAndCounterParty from "./transactionAndCounterparty";
import Overview from "./overview";
import { useParams } from "react-router-dom";
import DetailsModal from "../../components/biWeeklyDepositModals/detailsModal";
import InvolvedAddressModal from "../../components/biWeeklyDepositModals/involvedAddressModal";
import {
  InvolvedAddressesRecord,
  TransactionDataRecord,
} from "store/biWeeklyDeposit/types";
import { Box } from "grommet";

const BiWeeklyDeposit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isInvolvedAddressModalVisible, setIsInvolvedAddressModalVisible] =
    useState(false);
  const [detailsModalData, setDetailsModalData] = useState<
    TransactionDataRecord[]
  >([]);
  const [involvedAddressModalData, setInvolvedAddressModalData] = useState<
    InvolvedAddressesRecord[]
  >([]);
  const [detailsModalTitle, setDetailsModalTitle] = useState("");
  const [involvedAddressModalTitle, setInvolvedAddressModalTitle] =
    useState("");

  const {
    biWeeklyDepositTrends,
    involvedAddresses,
    isLoadingBiWeeklyDepositTrends,
    isLoadingInvolvedAddresses,
  } = useSelector((state: RootState) => state.biWeeklyDeposit);

  useEffect(() => {
    if (!id) return;
    dispatch(getBiWeeklyDepositDataAction({ baseId: id }));
    dispatch(getBiWeeklyDepositInvolvedAddressesAction({ baseId: id }));
  }, [dispatch, id]);

  const handleExpandDetails = (title: string, filterKey: string) => {
    const filteredData = biWeeklyDepositTrends.filter((record) =>
      record.fields.Page.includes(filterKey)
    );
    setDetailsModalData(filteredData);
    setDetailsModalTitle(title);
    setIsDetailsModalVisible(true);
  };

  const handleInvolvedAddresses = (title: string, filterKey: string) => {
    const filteredData = involvedAddresses.filter((record) =>
      record.fields.Page.includes(filterKey)
    );
    setInvolvedAddressModalData(filteredData);
    setInvolvedAddressModalTitle(title);
    setIsInvolvedAddressModalVisible(true);
  };

  if (isLoadingBiWeeklyDepositTrends || isLoadingInvolvedAddresses) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Box gap="26px">
        <Overview handleInvolvedAddresses={handleInvolvedAddresses} />
        <RiskProfileAndBreakdown
          handleExpandDetails={handleExpandDetails}
          handleInvolvedAddresses={handleInvolvedAddresses}
        />
        <TransactionAndCounterParty
          handleExpandDetails={handleExpandDetails}
          handleInvolvedAddresses={handleInvolvedAddresses}
        />
      </Box>
      <DetailsModal
        isVisible={isDetailsModalVisible}
        onClose={() => setIsDetailsModalVisible(false)}
        data={detailsModalData}
        title={detailsModalTitle}
      />
      <InvolvedAddressModal
        isVisible={isInvolvedAddressModalVisible}
        onClose={() => setIsInvolvedAddressModalVisible(false)}
        data={involvedAddressModalData}
        title={involvedAddressModalTitle}
      />
    </div>
  );
};

export default BiWeeklyDeposit;
