import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMonthlyDepositTrendsDataAction,
  getDepositExposureCategoryBreakdownDataAction,
  getMonthlyDepositChartsDataAction,
} from "./monthlyDepositTrendsActions";
import {
  MonthlyDepositTrendsState,
  TransactionDataPayload,
  TransactionChartDataPayload,
} from "./types";

const initialState: MonthlyDepositTrendsState = {
  monthlyDepositTrends: [],
  depositExposureCategoryBreakdown: [],
  monthlyDepositCharts: [],
  isLoadingMonthlyDepositTrends: false,
  isLoadingDepositExposureCategoryBreakdown: false,
  isLoadingMonthlyDepositCharts: false,
};

const monthlyDepositTrendsSlice = createSlice({
  name: "monthlyDepositTrends",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Monthly Deposit Trends Data
      .addCase(getMonthlyDepositTrendsDataAction.pending, (state) => {
        state.isLoadingMonthlyDepositTrends = true;
      })
      .addCase(
        getMonthlyDepositTrendsDataAction.fulfilled,
        (state, action: PayloadAction<TransactionDataPayload>) => {
          state.isLoadingMonthlyDepositTrends = false;
          state.monthlyDepositTrends = action.payload.transactionData;
        }
      )
      .addCase(getMonthlyDepositTrendsDataAction.rejected, (state) => {
        state.isLoadingMonthlyDepositTrends = false;
      })
      // Deposit Exposure Category Breakdown Data
      .addCase(getDepositExposureCategoryBreakdownDataAction.pending, (state) => {
        state.isLoadingDepositExposureCategoryBreakdown = true;
      })
      .addCase(
        getDepositExposureCategoryBreakdownDataAction.fulfilled,
        (state, action: PayloadAction<TransactionDataPayload>) => {
          state.isLoadingDepositExposureCategoryBreakdown = false;
          state.depositExposureCategoryBreakdown = action.payload.transactionData;
        }
      )
      .addCase(getDepositExposureCategoryBreakdownDataAction.rejected, (state) => {
        state.isLoadingDepositExposureCategoryBreakdown = false;
      })
      // Monthly Deposit Charts Data
      .addCase(getMonthlyDepositChartsDataAction.pending, (state) => {
        state.isLoadingMonthlyDepositCharts = true;
      })
      .addCase(
        getMonthlyDepositChartsDataAction.fulfilled,
        (state, action: PayloadAction<TransactionChartDataPayload>) => {
          state.isLoadingMonthlyDepositCharts = false;
          state.monthlyDepositCharts = action.payload.transactionChartData;
        }
      )
      .addCase(getMonthlyDepositChartsDataAction.rejected, (state) => {
        state.isLoadingMonthlyDepositCharts = false;
      });
  },
});

export default monthlyDepositTrendsSlice.reducer;
