import React from "react";
import { Typography, Row, Col } from "antd";
import { ProviderFields } from "store/providers/types";
import styles from "./entityOverview.module.scss";
import { Box } from "grommet";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;

const EntityOverview: React.FC<{
  provider: ProviderFields;
  warningCount: number;
}> = ({ provider, warningCount }) => {
  const { id } = useParams<{ id: string }>();
  const formattedLicenceActivities = provider["Licence Activities"]
    ? provider["Licence Activities"].map((activity: string, index: number) => (
        <li key={index}>{activity}</li>
      ))
    : "-";

  const formattedLicenseConditions = provider[
    "VARA License Condition and Restrictions"
  ]
    ? provider["VARA License Condition and Restrictions"]
        .split(";")
        .map((condition: string, index: number) => (
          <li key={index}>{condition}</li>
        ))
    : "-";

  const getValue = (key: string): string | number => {
    return (
      provider[
        key as keyof Omit<
          ProviderFields,
          | "Licence Activities"
          | "Logo"
          | "On-ramp and Off-ramp Options"
          | "Fiat & VA Alert"
          | "VA Alert"
          | "Fiat Alert"
          | "disableBiWeekly"
          | "disableBiWeeklyTrend"
        >
      ] || "-"
    );
  };

  const onRampOffRampOptions =
    provider["On-ramp and Off-ramp Options"]?.join(", ");

  return (
    <Box className={styles.container}>
      <div style={{ right: 50, textAlign: "right" }}>
        <div className={warningCount ? styles.alert : styles.noAlert}>
          Alerts on this page: {warningCount}
        </div>
        <div>Case Officer: {getValue("Case Officer")}</div>
        <div>VASP Contact: {getValue("VASP Contact")}</div>
      </div>
      <Box gap={"48px"}>
        <div>
          <Title level={1}>{getValue("Entity Name")}</Title>
        </div>
        {id !== "app7eBzFnPP4uIkKz" && (
          <>
            <div>
              <Title level={2}>Overview</Title>
              <Row gutter={[0, 0]}>
                <Col xs={24} md={8}>
                  <div>
                    <div className={styles.textContainer}>
                      <Title level={5}>Entity Name</Title>
                    </div>
                    <Text>{getValue("Entity Name")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>Trading Name</Title>
                    <Text>{getValue("Trading Name")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <Title level={5}>On-ramp & Off-ramp Options</Title>
                  <Text>{onRampOffRampOptions || "-"}</Text>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>CEO</Title>
                    <Text>{getValue("CEO")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>CCO</Title>
                    <Text>{getValue("CCO")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>COO</Title>
                    <Text>{getValue("COO")}</Text>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Title level={2}>License Info</Title>
              <Row gutter={[0, 0]}>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>Commercial Licensing Authority</Title>
                    <Text>{getValue("Commercial Licensing Authority")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>Commercial License No.</Title>
                    <Text>{getValue("Commercial License Number")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>Commercial License Expiry Date</Title>
                    <Text>{getValue("Commercial License Expiry Date")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>VARA License No.</Title>
                    <Text>{getValue("VARA License Number")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <Title level={5}>VARA License Expiry Date</Title>
                    <Text>{getValue("VARA License Expiry Date")}</Text>
                  </div>
                </Col>
                <Col xs={24} md={24}>
                  <div>
                    <Title level={5}>Licence Activities</Title>
                    <div>
                      <ul>{formattedLicenceActivities}</ul>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={24}>
                  <div>
                    <Title level={5}>
                      VARA License Condition & Restrictions
                    </Title>
                    <div>
                      <ul>{formattedLicenseConditions}</ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EntityOverview;
