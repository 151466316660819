// monthlyDepositTrendsActions.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import AirtableApiClient from "utils/airtableApiClient";
import {
  TransactionDataRecord,
  TransactionDataPayload,
  TransactionChartDataPayload,
  TransactionChartDataRecord,
} from "./types";

export const getMonthlyDepositTrendsDataAction = createAsyncThunk<
  TransactionDataPayload,
  { baseId: string },
  {}
>("monthlyDeposit/getTrends", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const transactionData =
      await AirtableApiClient.fetchAllRecords<TransactionDataRecord>(
        client,
        "Transaction Monitoring?view=Master view&filterByFormula=AND(FIND(%22Monthly+Trends+Over+The+Time;%22%2C+%7BPage%7D),FIND(%22Overview;%22%2C+%7BPage%7D))"
      );
    return { transactionData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});

export const getDepositExposureCategoryBreakdownDataAction = createAsyncThunk<
  TransactionDataPayload,
  { baseId: string },
  {}
>("monthlyDeposit/getCategoryBreakdown", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const transactionData =
      await AirtableApiClient.fetchAllRecords<TransactionDataRecord>(
        client,
        "Transaction Monitoring?view=Master view&filterByFormula=AND(FIND(%22Monthly+Trends+Over+The+Time;%22%2C+%7BPage%7D),FIND(%22Deposit+Exposure+to+Category+Breakdown;%22%2C+%7BPage%7D))"
      );
    return { transactionData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});

export const getMonthlyDepositChartsDataAction = createAsyncThunk<
  TransactionChartDataPayload,
  { baseId: string },
  {}
>("monthlyDeposit/getCharts", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const transactionChartData =
      await AirtableApiClient.fetchAllRecords<TransactionChartDataRecord>(
        client,
        "Transaction Monitoring Chart?view=Master view&filterByFormula=FIND(%22Monthly+Trends+Over+The+Time;%22%2C+%7BPage%7D)"
      );

    return { transactionChartData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
