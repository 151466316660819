import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { monthNames } from "utils/constants";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  ChartTooltip,
  Legend
);
interface FinancialChartProps {
  chartData: number[];
  lastUpdateMonth: string;
  label: string;
  showLegend?: boolean;
  takeAllSpace?: boolean;
  maintainAspectRatio?: boolean;
  showIntegersOnly?: boolean;
}

const FinancialChart: React.FC<FinancialChartProps> = ({
  chartData,
  lastUpdateMonth,
  label,
  showLegend = false,
  takeAllSpace = false,
}) => {
  const valueArray: (number | undefined)[] = chartData.slice(0, 6);
  while (valueArray.length < 6) {
    valueArray.push(undefined);
  }

  const currentMonthIndex = monthNames.indexOf(lastUpdateMonth);
  const currentYear = new Date().getFullYear();

  if (currentMonthIndex === -1) {
    return null;
  }

  const labels: string[] = [];

  for (let i = currentMonthIndex; i > currentMonthIndex - 6; i--) {
    const monthIndex = i < 0 ? i + 12 : i;
    const year = i < 0 ? currentYear - 1 : currentYear;
    labels.push(`${monthNames[monthIndex]} ${year}`);
  }

  const data = {
    labels: labels.reverse(), // Reverse to display in chronological order
    datasets: [
      {
        label: label,
        data: valueArray.reverse(), // Ensure data points align with the labels
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: showLegend,
      },
    },
    responsive: true,
    maintainAspectRatio: takeAllSpace ? false : true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return takeAllSpace ? (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={data} options={options} />
    </div>
  ) : (
    <Line data={data} options={options} />
  );
};

export default FinancialChart;
