import { createSlice } from "@reduxjs/toolkit";
import { getProvidersAction } from "./providersActions";
import { Provider } from "./types";

export interface IWorkspaceDetailSliceState {
  providers: Provider[];
  isLoading: boolean;
}

const initialState: IWorkspaceDetailSliceState = {
  providers: [],
  isLoading: false,
};

export const workspaceSlice = createSlice({
  name: "workspaceDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProvidersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.providers = action.payload.providers;
      })
      .addCase(getProvidersAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default workspaceSlice.reducer;
