import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography, Tooltip } from "antd";
import { RootState } from "store";
import { Box } from "grommet";
import styles from "./overview.module.scss";
import FinancialStatistic from "components/financialStatistic/financialStatistic";

const { Title } = Typography;

const Overview: React.FC = () => {
  const transactionData = useSelector(
    (state: RootState) => state.biWeeklyDepositTrends.transactionData
  );

  const biWeeklyDepositTrends = useSelector(
    (state: RootState) => state.biWeeklyDeposit.biWeeklyDepositTrends
  );

  const getValue = (key: string): number | undefined => {
    const record = transactionData?.transactionData.find(
      (record) => record.fields.Key === key
    );

    return record?.fields.Value !== undefined
      ? record?.fields.Value
      : undefined;
  };

  const lastScreenedRow = biWeeklyDepositTrends.find(
    (record) => record.fields.Key === "Last Screened"
  );

  const ListedTokenRow = biWeeklyDepositTrends.find(
    (record) => record.fields.Key === "Listed Token"
  );

  return (
    <div className={styles.container}>
      <Box align="end">
        <div>Last Screened: {lastScreenedRow?.fields.Date || "N/A"}</div>
        <div>
          {ListedTokenRow?.fields.Value
            ? `${ListedTokenRow?.fields.Value}% of tokens are covered.`
            : " "}{" "}
          {ListedTokenRow?.fields.Value && (
            <Tooltip title={ListedTokenRow?.fields.List?.replaceAll(";", ", ")}>
              <u>List of unsupported tokens</u>
            </Tooltip>
          )}
        </div>
      </Box>
      <div>
        <Title level={2}>Summary</Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={5}>
              Riskiest Percentile Addresses: 2-Week Deposit Volume
            </Title>
            <FinancialStatistic
              value={getValue(
                "Riskiest Percentile Addresses 2-Week Deposit Volume"
              )}
            />
          </Col>
          <Col span={12}>
            <Title level={5}>
              Total Number of Riskiest Percentile Addresses
            </Title>
            <FinancialStatistic
              value={getValue("Total Number of Riskiest Percentile Addresses")}
            />
          </Col>
          <Col span={12}>
            <Title level={5}>
              Riskiest Addresses with New Risky Transactions Since Last Screen
            </Title>
            <FinancialStatistic
              value={getValue(
                "Last Screen Riskiest Addresses with New Risky Transactions"
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Overview;
