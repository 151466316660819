import { useEffect } from "react";
import { getProvidersAction } from "../../store/providers/providersActions";
import { useAppDispatch } from "../../store";

const useFetchProviders = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProvidersAction());
  }, [dispatch]);

  return null;
};

export default useFetchProviders;
