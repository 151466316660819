import React from "react";
import { Modal, Table } from "antd";
import { TransactionDataRecord } from "store/biWeeklyDeposit/types";
import { severityOrder } from "utils/constants";

interface DetailsModalProps {
  isVisible: boolean;
  title: string;
  data: TransactionDataRecord[];
  onClose: () => void;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  isVisible,
  title,
  data,
  onClose,
}) => {
  const getPercentage = (value: number, total: number) => {
    if (value === 0 || total === 0) return "-";
    return ((value / total) * 100).toFixed(2) + "%";
  };

  const totalRiskTransferValue = data.reduce(
    (acc, record) => acc + (record.fields.Value || 0),
    0
  );

  const modalColumns = [
    {
      title: "Severity",
      dataIndex: ["fields", "Severity"],
      key: "Severity",
    },
    {
      title: "Category",
      dataIndex: ["fields", "Key"],
      key: "Category",
    },
    {
      title: "%",
      dataIndex: "percentage",
      key: "percentage",
      render: (_: any, record: TransactionDataRecord) =>
        getPercentage(record.fields.Value || 0, totalRiskTransferValue),
    },
    {
      title: "Volume (USD)",
      dataIndex: ["fields", "Value"],
      key: "Volume",
      render: (value: number) =>
        value !== undefined
          ? `$${Number(value.toFixed(2)).toLocaleString()}`
          : "N/A",
    },
  ];

  const sortedData = data.sort((a, b) => {
    const severityA =
      severityOrder[a.fields.Severity as keyof typeof severityOrder];
    const severityB =
      severityOrder[b.fields.Severity as keyof typeof severityOrder];
    if (severityA !== severityB) {
      return severityA - severityB;
    }
    return (a.fields.Order || 0) - (b.fields.Order || 0);
  });

  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
        dataSource={sortedData}
        columns={modalColumns}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};

export default DetailsModal;
