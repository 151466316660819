import { createAsyncThunk } from "@reduxjs/toolkit";
import AirtableApiClient from "../../utils/airtableApiClient";
import { NewsPayload, NewsRecord } from "./types";

export const getNewsDataAction = createAsyncThunk<
  NewsPayload,
  { baseId: string },
  {}
>("news/getNews", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const newsData = await AirtableApiClient.fetchAllRecords<NewsRecord>(
      client,
      "News?view=Master view"
    );
    return { newsData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
