import React from "react";
import "./App.scss";
import NavBar from "./components/navBar";
import { PublicRoute, ProtectedRoute } from "./components/routeGuards";
import LoginPage from "./pages/login/loginPage";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProfileDetails from "./pages/profileDetails/profileDetails";
import Providers from "./pages/providers/providers";
import routes from "./constants/routes";
import UserProvider from "./contexts/userProvider";
import ChangePasswordPage from "pages/changePassword/changePassword";

function App() {
  const location = useLocation();
  return (
    <UserProvider>
      <div className="App">
        <Routes location={location}>
          <Route
            path={routes.login}
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.dashboard}
            element={
              <ProtectedRoute>
                <NavBar />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to={routes.providers} />} />
            <Route path={routes.providers} element={<Providers />} />
            <Route
              path={routes.singleProfileParent}
              element={<ProfileDetails />}
            />
            <Route
              path={routes.singleProfileChild}
              element={<ProfileDetails />}
            />
            <Route
              path={routes.changePassword}
              element={<ChangePasswordPage />}
            />
          </Route>
          <Route path="*" element={<Navigate to={routes.login} />} />
        </Routes>
      </div>
    </UserProvider>
  );
}

export default App;
