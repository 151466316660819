import { createAsyncThunk } from "@reduxjs/toolkit";
import { Provider, ProvidersPayload } from "./types";
import AirtableApiClient from "utils/airtableApiClient";

export const getProvidersAction = createAsyncThunk<ProvidersPayload, void, {}>(
  "providers/get",
  async (_, thunkAPI) => {
    try {
      const client = AirtableApiClient.getProvidersInstance();
      const providers = await AirtableApiClient.fetchAllRecords<Provider>(
        client,
        "/Providers"
      );
      return { providers };
    } catch (err) {
      if (err instanceof Error) {
        return thunkAPI.rejectWithValue({ error: err.message });
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" });
    }
  }
);
