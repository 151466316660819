import React from "react";
import { Row, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styles from "./transactionLevelAnalysis.module.scss";
import { Box } from "grommet";
import FinancialStatistic from "components/financialStatistic/financialStatistic";

const { Title } = Typography;

const TransactionLevelAnalysis: React.FC = () => {
  const { transactionData } = useSelector(
    (state: RootState) => state.biWeeklyDepositTrends
  );

  const getValue = (key: string): number | undefined => {
    const record = transactionData?.transactionData.find(
      (record) => record.fields.Key === key
    );
    return record?.fields.Value || undefined;
  };

  const renderCard = (title: string, value: number | undefined) => (
    <div>
      <Title level={5}>{title}</Title>
      <FinancialStatistic value={value} />
    </div>
  );

  const totalDepositVolume = getValue(
    "Total Deposit Volume In The Past Two Weeks (BTC & ETH)"
  );
  const totalTransactions = getValue(
    "Total Number of Transactions In The Past Two Weeks (BTC & ETH)"
  );
  const highRiskVolume = getValue(
    "Total Volume of High-Risk Transactions In The Past Two Weeks (BTC & ETH)"
  );
  const highRiskTransactions = getValue(
    "Total Number of High-Risk Transactions In The Past Two Weeks (BTC & ETH)"
  );

  return (
    <Box className={styles.container} gap={"26px"}>
      <div>
        <Title level={2}>
          Transaction Level Analysis (BTC & ETH) - General
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {renderCard(
              "Total Deposit Volume In The Past Two Weeks (BTC & ETH)",
              totalDepositVolume
            )}
          </Col>
          <Col span={12}>
            {renderCard(
              "Total Number of Transactions In The Past Two Weeks (BTC & ETH)",
              totalTransactions
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Title level={2}>
          Transaction Level Analysis (BTC & ETH) - High Risk
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {renderCard(
              "Total Volume of High-Risk Transactions In The Past Two Weeks (BTC & ETH)",
              highRiskVolume
            )}
          </Col>
          <Col span={12}>
            {renderCard(
              "Total Number of High-Risk Transactions In The Past Two Weeks (BTC & ETH)",
              highRiskTransactions
            )}
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default TransactionLevelAnalysis;
