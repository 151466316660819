import { useState } from "react";
import { Box } from "grommet";
import styles from "./loginPage.module.scss";
import type { FormProps } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/userProvider";
import routes from "constants/routes";
import { ReactComponent as CoverLogo } from "../../assets/coverLogo.svg";
import axios from "axios";
import message from "components/message/message";

const { Title } = Typography;

type FieldType = {
  username: string;
  password: string;
  remember: boolean;
};

const Login = () => {
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const userContext = useUserContext();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const rememberMe = values.remember || false;

    setLoading(true); // Set loading to true when request starts

    try {
      const response = await axios.post(
        "https://crosschain-backend.netlify.app/api/users/login",
        {
          username: values.username,
          password: values.password,
          rememberMe: rememberMe,
        },
        { withCredentials: true } // Include credentials (cookies)
      );

      if (response.status === 200) {
        message.success("Login success!");
        userContext.onLogin({ name: values.username });
        navigate(`/dashboard/${routes.providers}`);
      } else {
        const errorMessage =
          response.data?.message ||
          "Login failed. Please check your credentials and try again.";

        message.error(errorMessage);
      }
    } catch (error: any) {
      const errorMessage =
        error.response.data || "Login error. Please try again.";

      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className={styles.container}>
      <Col span={12}>
        <Box style={{ height: "100vh" }}>
          <Box
            style={{
              borderRadius: 24,
              margin: 8,
              height: "calc(100vh - 16px)",
              width: "100%",
            }}
            align="center"
            justify="center"
            className={styles.coverImageContainer}
          >
            <CoverLogo style={{ zIndex: 999, color: "white" }} fill="white" />
          </Box>
        </Box>
      </Col>
      <Col span={12}>
        <Box style={{ height: "100vh" }} align={"center"} justify={"center"}>
          <Card className={styles.loginCard}>
            <Title level={2} className={styles.title}>
              Login Account
            </Title>
            <Form
              name="basic"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item<FieldType>
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#555555",
                    colorPrimaryHover: "#bfbfbf",
                    colorBgContainer: "#555555",
                    colorBorder: "#bfbfbf",
                  },
                }}
              >
                <Form.Item<FieldType> name="remember" valuePropName="checked">
                  <Checkbox>Remember me for 30 days</Checkbox>
                </Form.Item>
              </ConfigProvider>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginButton}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Box>
      </Col>
    </Row>
  );
};

export default Login;
