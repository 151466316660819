import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getMonthlyDepositTrendsDataAction,
  getDepositExposureCategoryBreakdownDataAction,
  getMonthlyDepositChartsDataAction,
} from "store/monthlyDepositTrends/monthlyDepositTrendsActions";
import OverviewAndRiskProfile from "./overviewAndRiskProfile";
import DepositExposureToRiskBreakdown from "./depositExposureToRiskBreakdown";
import DepositExposureToCategoryBreakdown from "./depositExposureToCategoryBreakdown";
import { Spin } from "antd";
import { RootState, useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { Box } from "grommet";

const MonthlyDepositTrends: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(getMonthlyDepositTrendsDataAction({ baseId: id }));
    dispatch(getDepositExposureCategoryBreakdownDataAction({ baseId: id }));
    dispatch(getMonthlyDepositChartsDataAction({ baseId: id }));
  }, [dispatch, id]);

  const {
    isLoadingMonthlyDepositTrends,
    isLoadingDepositExposureCategoryBreakdown,
    isLoadingMonthlyDepositCharts,
  } = useSelector((state: RootState) => state.monthlyDepositTrends);

  if (
    isLoadingMonthlyDepositTrends ||
    isLoadingDepositExposureCategoryBreakdown ||
    isLoadingMonthlyDepositCharts
  ) {
    return <Spin size="large" />;
  }

  return (
    <Box gap="26px">
      <OverviewAndRiskProfile />
      <DepositExposureToRiskBreakdown />
      <DepositExposureToCategoryBreakdown />
    </Box>
  );
};

export default MonthlyDepositTrends;
