import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Card, Row, Col, Spin, Typography, Button } from "antd";
import FinancialChart from "components/financialChart/financialChart";
import { MonitoringChartRecord } from "store/biWeeklyDepositTrends/types";
import FinancialStatistic from "components/financialStatistic/financialStatistic";
import styles from "./riskProfile.module.scss";
import { Box } from "grommet";

const { Title } = Typography;

const RiskProfile: React.FC = () => {
  const {
    transactionData,
    monitoringChartData,
    isLoadingBiWeeklyDepositTrends,
  } = useSelector((state: RootState) => state.biWeeklyDepositTrends);

  const [selectedChart, setSelectedChart] = useState<string>("Severe Risk");

  const getChartData = () => {
    const chartData: MonitoringChartRecord | undefined =
      monitoringChartData?.monitoringChartData.find(
        (item) =>
          item.fields.Name === selectedChart &&
          item.fields.Page.includes(
            "Number of Associated Wallet Address Per Severity Level;"
          )
      );

    if (!chartData) {
      return null;
    }

    const valueArray =
      chartData.fields.Value?.toString().split(";").map(Number) || [];
    const lastUpdateMonth = chartData.fields["Last Update Month"];

    return { valueArray, lastUpdateMonth };
  };

  if (isLoadingBiWeeklyDepositTrends) {
    return <Spin size="large" />;
  }

  const chartData = getChartData();

  const getValue = (key: string) => {
    const target = transactionData?.transactionData.find(
      (item) => item.fields.Key === key
    );
    return target?.fields.Value;
  };

  return (
    <div className={styles.container}>
      <Title level={2}>Wallet Address Risk Profile</Title>
      <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
        <Col sm={24} xxl={12}>
          <Card className={styles.innerCard}>
            <div className={styles.chartHeader}>
              <Title level={4}>Number of {selectedChart} Address</Title>
              <Box direction="row" gap={"8px"}>
                {monitoringChartData?.monitoringChartData
                  .filter((item) =>
                    item.fields.Page.includes(
                      "Number of Associated Wallet Address Per Severity Level;"
                    )
                  )
                  .map((item) => (
                    <Button
                      key={item.fields.Name}
                      className={`${styles.graphTab} ${
                        selectedChart === item.fields.Name && styles.selected
                      }`}
                      onClick={() => setSelectedChart(item.fields.Name)}
                    >
                      {item.fields.Name}
                    </Button>
                  ))}
              </Box>
            </div>
            {chartData && (
              <FinancialChart
                chartData={chartData.valueArray}
                lastUpdateMonth={chartData.lastUpdateMonth}
                label={`No. of ${selectedChart} Addresses`}
              />
            )}
          </Card>
        </Col>

        <Col sm={24} xxl={12}>
          <Box margin={{ top: "48px" }}>
            <Title level={4}>
              No. of Associated Wallet Address Per Severity Level
            </Title>
          </Box>
          <Row gutter={[0, 24]}>
            {[
              {
                label: "No. of Severe Risk Addresses Bi-weekly Change",
                key: "Number of Severe Risk Addresses",
              },
              {
                label: "No. of High Risk Addresses Bi-weekly Change",
                key: "Number of High Risk Addresses",
              },
              {
                label: "No. of Medium Risk Addresses Bi-weekly Change",
                key: "Number of Medium Risk Addresses",
              },
              {
                label: "No. of Low Risk Addresses Bi-weekly Change",
                key: "Number of Low Risk Addresses",
              },
            ].map(({ label, key }) => (
              <Col md={6} xxl={12} key={key}>
                <Title level={5}>{label}</Title>
                <FinancialStatistic value={getValue(key)} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RiskProfile;
