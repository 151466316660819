enum routes {
  login = "login",
  dashboard = "dashboard",
  providers = "providers",
  singleProfileParent = ":id",
  singleProfileChild = ":id/:tabName",
  changePassword = "change-password",
}

export default routes;
