import React, { useState } from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./changePassword.module.scss";
import { useUserContext } from "contexts/userProvider";
import message from "components/message/message";

const { Title } = Typography;

const passwordRegex = //eslint-disable-next-line
  /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?])(?=.*[0-9a-zA-Z]).{8,}$/;

const ChangePasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userContext = useUserContext();

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://crosschain-backend.netlify.app/api/users/change-password",
        {
          username: userContext.user?.name,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        { withCredentials: true } // Include credentials (cookies)
      );

      if (response.status === 200) {
        message.success("Password changed successfully!");
        navigate("/login");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      const errorMessage =
        error.response.data || "An error occurred. Please try again.";

      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.changePasswordContainer}>
      <Card className={styles.changePasswordCard}>
        <Title level={2} className={styles.title}>
          Change Password
        </Title>
        <Form
          form={form}
          name="change_password"
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please input your new password!" },
              {
                pattern: passwordRegex,
                message:
                  "Password must contain at least one capital letter, one special character, and be at least 8 characters long",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.changePasswordButton}
              loading={loading}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePasswordPage;
