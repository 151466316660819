import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupervisionOverviewRecord, SupervisionOverviewPayload } from "./types";
import AirtableApiClient from "utils/airtableApiClient";

export const getSupervisionOverviewAction = createAsyncThunk<
  SupervisionOverviewPayload,
  { baseId: string },
  {}
>("supervisionOverview/get", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const supervisionOverview =
      await AirtableApiClient.fetchAllRecords<SupervisionOverviewRecord>(
        client,
        "Supervision"
      );
    return { supervisionOverview };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
