import React, { useState } from "react";
import { Typography, Col, Row, Button, Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styles from "./riskProfile.module.scss";
import { Box } from "grommet";
import PercentageBarChart from "../../components/percentageBarChart/percentageBarChart";
import ClickableHeader from "../../components/clickableHeader/clickableHeader";

const { Title } = Typography;

const RiskProfile: React.FC = () => {
  const { data, involvedAddresses } = useSelector(
    (state: RootState) => state.monthlyDeposit
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<any[]>([]);
  const [modalTitle, setModalTitle] = useState("");

  const riskProfileData = data.filter((item) =>
    item.fields.Page.includes("Overview;")
  );

  const getValue = (key: string) => {
    const value = riskProfileData.find((item) => item.fields.Key === key)
      ?.fields.Value;
    return value !== undefined ? value : "N/A";
  };

  const totalAddresses =
    riskProfileData.find(
      (item) => item.fields.Key === "Total Number of Wallet Addresses"
    )?.fields.Value || 0;

  const severeRiskAddresses = getValue("Number of Severe Risk Addresses");
  const highRiskAddresses = getValue("Number of High Risk Addresses");
  const mediumRiskAddresses = getValue("Number of Medium Risk Addresses");
  const lowRiskAddresses = getValue("Number of Low Risk Addresses");

  const calculatePercentage = (value: string | number) => {
    if (typeof value === "number") {
      return (value / totalAddresses) * 100;
    }
    return 0;
  };

  const showModal = (title: string, key: string) => {
    const data = involvedAddresses
      .filter((item) => item.fields.Page.includes(key))
      .map((d) => ({
        key: d.fields.Addresses,
        severity: d.fields.Severity,
        category: d.fields.Category,
        address: d.fields.Addresses,
        usd: d.fields.Amount,
      }));

    setModalContent(data);
    setModalTitle(title);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Addresses",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "USD",
      dataIndex: "usd",
      key: "usd",
      render: (value: number) =>
        value !== undefined
          ? `$${Number(value.toFixed(2)).toLocaleString()}`
          : "N/A",
    },
  ];

  const proposedAction = (severity: string) => {
    if (severity === "Severe") {
      return (
        <>
          <p>Send RFI to related VASP to obtain:</p>
          <ul>
            <li>All addresseses associated with the user</li>
            <li>User on-chain withdrawal details [past 30 days]</li>
            <li>
              Risks identified by VASP + actions taken in regards to the user
              [past 30 days]
            </li>
            <li>VASP TM screening records of user [past 30 days]</li>
          </ul>
        </>
      );
    }
    if (severity === "High") {
      return (
        <>
          <p>Send RFI to related VASP to obtain:</p>
          <ul>
            <li>All addresseses associated with the user</li>
            <li>User on-chain withdrawal details [past 30 days]</li>
            <li>
              Risks identified by VASP + actions taken in regards to the user
              [past 30 days]
            </li>
          </ul>
        </>
      );
    }
  };

  const severeRiskPercentage =
    severeRiskAddresses === "N/A"
      ? 0
      : calculatePercentage(severeRiskAddresses);
  const highRiskPercentage =
    highRiskAddresses === "N/A" ? 0 : calculatePercentage(highRiskAddresses);
  const mediumRiskPercentage =
    mediumRiskAddresses === "N/A"
      ? 0
      : calculatePercentage(mediumRiskAddresses);
  const lowRiskPercentage =
    lowRiskAddresses === "N/A" ? 0 : calculatePercentage(lowRiskAddresses);

  const percentages = [
    severeRiskPercentage,
    highRiskPercentage,
    mediumRiskPercentage,
    lowRiskPercentage,
  ];

  const categories = [
    {
      label: "Severe Risk Addresses",
      value: severeRiskAddresses,
      onClick: () => showModal("Severe Risk Addresses", "Severe Risk;"),
    },
    {
      label: "High Risk Addresses",
      value: highRiskAddresses,
      onClick: () => showModal("High Risk Addresses", "High Risk;"),
    },
    { label: "Medium Risk Addresses", value: mediumRiskAddresses },
    { label: "Low Risk Addresses", value: lowRiskAddresses },
  ];

  return (
    <Box className={styles.container}>
      <Title level={2}>Wallet Address Risk Profile</Title>
      <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
        <Col sm={24} xxl={12}>
          <PercentageBarChart
            percentages={percentages}
            xAxisTitle="Risk Category"
            yAxisTitle="Percentage"
            headerTitle="Percentage of Risks Addresses by Category"
          />
        </Col>
        <Col sm={24} xxl={12}>
          <Box margin={{ top: "48px" }}>
            <Title level={4}>No. of Exact Risks Addresses by Category</Title>
          </Box>
          <Row gutter={[0, 24]} style={{ marginBottom: 24, marginTop: 12 }}>
            {categories.map((category) => (
              <Col md={6} xxl={12} key={category.label}>
                {category.onClick ? (
                  <ClickableHeader
                    title={category.label}
                    onClick={category.onClick}
                  />
                ) : (
                  <Title level={5}>{category.label}</Title>
                )}
                <div className={styles.textContainer}>{category.value}</div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Modal
        title={`Involved Addresses - ${modalTitle}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800} // Increase the width of the modal
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <Table dataSource={modalContent} columns={columns} pagination={false} />
        <div
          style={{
            backgroundColor: "#ccf0f5",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          <Title level={5}>Proposed Action</Title>
          {proposedAction(modalTitle.split(" ")[0])}
        </div>
      </Modal>
    </Box>
  );
};

export default RiskProfile;
