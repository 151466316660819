import React from "react";
import { Typography, Col, Row } from "antd";
import styles from "./riskBreakdown.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Box } from "grommet";
import PercentageBarChart from "../../components/percentageBarChart/percentageBarChart"; // Adjust the path to your custom component

const { Title } = Typography;

const RiskBreakdown: React.FC = () => {
  const data = useSelector((state: RootState) => state.monthlyDeposit.data);
  const riskBreakdownData = data.filter((item) =>
    item.fields.Page.includes("Overview;")
  );

  const getValue = (key: string) => {
    const value = riskBreakdownData.find((item) => item.fields.Key === key)
      ?.fields.Value;
    return value !== undefined ? value : "N/A";
  };

  const severeValue = getValue(
    "Severe Risk Deposit Exposure to Risk Breakdown"
  );
  const highValue = getValue("High Risk Deposit Exposure to Risk Breakdown");
  const mediumValue = getValue(
    "Medium Risk Deposit Exposure to Risk Breakdown"
  );
  const lowValue = getValue("Low Risk Deposit Exposure to Risk Breakdown");

  const isAnyValueNA = [severeValue, highValue, mediumValue, lowValue].includes(
    "N/A"
  );
  const totalValue = isAnyValueNA
    ? 1
    : Number(severeValue) +
      Number(highValue) +
      Number(mediumValue) +
      Number(lowValue);

  const calculatePercentage = (value: string | number) => {
    if (typeof value === "number") {
      return (value / totalValue) * 100;
    }

    return 0;
  };

  const severeRiskPercentage =
    severeValue === "N/A" ? 0 : calculatePercentage(severeValue);
  const highRiskPercentage =
    highValue === "N/A" ? 0 : calculatePercentage(highValue);
  const mediumRiskPercentage =
    mediumValue === "N/A" ? 0 : calculatePercentage(mediumValue);
  const lowRiskPercentage =
    lowValue === "N/A" ? 0 : calculatePercentage(lowValue);

  const percentages = [
    severeRiskPercentage,
    highRiskPercentage,
    mediumRiskPercentage,
    lowRiskPercentage,
  ];

  const categories = [
    { label: "Severe", value: severeValue },
    { label: "High", value: highValue },
    { label: "Medium", value: mediumValue },
    { label: "Low", value: lowValue },
  ];

  return (
    <div className={styles.container}>
      <Title level={2}>Deposit Exposure to Risk Breakdown</Title>
      <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
        <Col sm={24} xxl={12}>
          <PercentageBarChart
            percentages={percentages}
            xAxisTitle="Risk Category"
            yAxisTitle="Percentage"
            headerTitle="Percentage of Deposits by Category"
          />
        </Col>
        <Col sm={24} xxl={12}>
          <Box margin={{ top: "48px" }}>
            <Title level={4}>Amount of Deposits by Category</Title>
          </Box>
          <Row gutter={[0, 24]} style={{ marginBottom: 24, marginTop: 12 }}>
            {categories.map((category) => (
              <Col md={6} xxl={12} key={category.label}>
                <Title level={5}>{category.label} (USD)</Title>
                {category.value !== "N/A" ? (
                  <div className={styles.textContainer}>
                    $
                    {Number(
                      (category.value as number).toFixed(2)
                    ).toLocaleString()}
                  </div>
                ) : (
                  category.value
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RiskBreakdown;
