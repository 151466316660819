import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMonthlyDepositDataAction,
  getMonthlyDepositInvolvedAddressesAction,
} from "./monthlyDepositActions";
import {
  InvolvedAddressesPayload,
  InvolvedAddressesRecord,
  TransactionData,
} from "./types";

interface MonthlyDepositState {
  data: TransactionData[];
  isLoadingTransaction: boolean;
  involvedAddresses: InvolvedAddressesRecord[];
  isLoadingInvolvedAddresses: boolean;
}

const initialState: MonthlyDepositState = {
  data: [],
  isLoadingTransaction: false,
  involvedAddresses: [],
  isLoadingInvolvedAddresses: false,
};

const monthlyDepositSlice = createSlice({
  name: "monthlyDeposit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlyDepositDataAction.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(getMonthlyDepositDataAction.fulfilled, (state, action) => {
        state.isLoadingTransaction = false;
        state.data = action.payload.transactionData;
      })
      .addCase(getMonthlyDepositDataAction.rejected, (state) => {
        state.isLoadingTransaction = false;
      })
      .addCase(getMonthlyDepositInvolvedAddressesAction.pending, (state) => {
        state.isLoadingInvolvedAddresses = true;
      })
      .addCase(
        getMonthlyDepositInvolvedAddressesAction.fulfilled,
        (state, action: PayloadAction<InvolvedAddressesPayload>) => {
          state.isLoadingInvolvedAddresses = false;
          state.involvedAddresses = action.payload.involvedAddressesData;
        }
      )
      .addCase(getMonthlyDepositInvolvedAddressesAction.rejected, (state) => {
        state.isLoadingInvolvedAddresses = false;
      });
  },
});

export default monthlyDepositSlice.reducer;
