import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
} from "react";
import Cookies from "js-cookie";
import axios from "axios";

interface IUser {
  name: string;
}

interface UserContextProps {
  user: IUser | null;
  onLogin: (user: IUser) => void;
  onLogout: () => void;
  loading: boolean;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  onLogin: () => {},
  onLogout: () => {},
  loading: true,
});

type Props = {
  children?: React.ReactNode;
};

const UserProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const verifyToken = async () => {
      const authToken = Cookies.get("authToken");
      if (authToken && storedUser) {
        try {
          const response = await axios.get(
            "https://crosschain-backend.netlify.app/api/users/verify-token",
            {
              withCredentials: true,
            }
          );

          if (response.status === 200) {
            if (storedUser) {
              setUser(JSON.parse(storedUser));
            }
          } else {
            setUser(null);
          }
        } catch (error) {
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    verifyToken();
  }, []);

  const onLogin = (user: IUser) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    Cookies.set("authToken", "true", { sameSite: "none", secure: true });
    setLoading(false);
  };

  const onLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    Cookies.remove("authToken", { sameSite: "none", secure: true });
    setLoading(false);
  };

  return (
    <UserContext.Provider value={{ user, onLogin, onLogout, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

export default UserProvider;
