import axios, { AxiosInstance } from "axios";

const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

if (!AIRTABLE_API_KEY) {
  throw new Error("Airtable API key is not set in environment variables.");
}

class AirtableApiClient {
  private static instances: { [key: string]: AxiosInstance } = {};

  private constructor() {}

  private static createInstance(baseId: string): AxiosInstance {
    return axios.create({
      baseURL: `https://api.airtable.com/v0/${baseId}`,
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });
  }

  public static getProvidersInstance(): AxiosInstance {
    const baseId = process.env.REACT_APP_PROVIDER_BASE_ID;

    if (!baseId) {
      throw new Error("Provider base ID is not set in environment variables.");
    }

    if (!AirtableApiClient.instances[baseId]) {
      AirtableApiClient.instances[baseId] =
        AirtableApiClient.createInstance(baseId);
    }
    return AirtableApiClient.instances[baseId];
  }

  public static getUsersInstance(): AxiosInstance {
    const baseId = process.env.REACT_APP_USER_BASE_ID;

    if (!baseId) {
      throw new Error("User base ID is not set in environment variables.");
    }

    if (!AirtableApiClient.instances[baseId]) {
      AirtableApiClient.instances[baseId] =
        AirtableApiClient.createInstance(baseId);
    }
    return AirtableApiClient.instances[baseId];
  }

  public static getInstanceDynamic(baseId: string): AxiosInstance {
    if (!AirtableApiClient.instances[baseId]) {
      AirtableApiClient.instances[baseId] = axios.create({
        baseURL: `https://api.airtable.com/v0/${baseId}`,
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        },
      });
    }
    return AirtableApiClient.instances[baseId];
  }

  public static async fetchAllRecords<T>(
    instance: AxiosInstance,
    tableName: string
  ): Promise<T[]> {
    let allRecords: T[] = [];
    let offset: string | undefined = undefined;

    try {
      do {
        const response: { data: { records: T[]; offset?: string } } =
          await instance.get(tableName, {
            params: {
              offset: offset,
            },
          });

        const { records, offset: newOffset } = response.data;
        allRecords = [...allRecords, ...records];
        offset = newOffset;
      } while (offset);

      return allRecords;
    } catch (error) {
      throw error;
    }
  }
}

export default AirtableApiClient;
