import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styles from "./transactionAndCounterparty.module.scss";
import ClickableHeader from "components/clickableHeader/clickableHeader";
import FinancialTable from "components/financialTable/financialTable";
import { Box } from "grommet";

ChartJS.register(ArcElement, Tooltip, Legend);

const { Title, Text } = Typography;

interface TransactionLevelAnalysisProps {
  handleExpandDetails: (title: string, filterKey: string) => void;
  handleInvolvedAddresses: (title: string, filterKey: string) => void;
}

const TransactionLevelAnalysis: React.FC<TransactionLevelAnalysisProps> = ({
  handleExpandDetails,
  handleInvolvedAddresses,
}) => {
  const biWeeklyDepositTrends = useSelector(
    (state: RootState) => state.biWeeklyDeposit.biWeeklyDepositTrends
  );

  const getValue = (key: string): number | "N/A" => {
    const record = biWeeklyDepositTrends.find(
      (record) => record.fields.Key === key
    );
    return record ? record.fields.Value || "N/A" : "N/A";
  };

  const getPercentage = (value: number | "N/A", total: number | "N/A") => {
    if (value === "N/A" || total === "N/A" || total === 0) return "N/A";
    return (value / total) * 100;
  };

  const renderCard = (
    title: string,
    value: number | "N/A",
    percentage: number | "N/A",
    buttonClickHandler?: () => void
  ) => {
    const isDollar = title.includes("USD");
    return (
      <>
        {buttonClickHandler ? (
          <ClickableHeader title={title} onClick={() => buttonClickHandler()} />
        ) : (
          <Title level={5}>{title}</Title>
        )}
        <Box direction="row" align="center" gap={"16px"}>
          <Text
            style={{
              textAlign: "left",
              display: "block",
            }}
          >
            {isDollar ? "$" : ""}
            {value.toLocaleString()}
          </Text>
          {percentage !== "N/A" ? `(${percentage.toFixed(2)}% of total)` : ""}
        </Box>
      </>
    );
  };

  const totalDepositVolume = getValue(
    "Total Deposit Volume In The Past Two Weeks (BTC & ETH)"
  );
  const totalTransactions = getValue(
    "Total Number of Transactions In The Past Two Weeks (BTC & ETH)"
  );

  const highRiskVolume = getValue(
    "Total Volume of High-Risk Transactions In The Past Two Weeks (BTC & ETH)"
  );
  const highRiskTransactions = getValue(
    "Total Number of High-Risk Transactions In The Past Two Weeks (BTC & ETH)"
  );

  const directRiskyTransfer = getValue(
    "Direct Risky Transfer In The Past Two Weeks"
  );
  const indirectRiskyTransfer = getValue(
    "Indirect Risky Transfer In The Past Two Weeks "
  );

  const highRiskVolumePercentage = getPercentage(
    highRiskVolume,
    totalDepositVolume
  );
  const highRiskTransactionsPercentage = getPercentage(
    highRiskTransactions,
    totalTransactions
  );

  const a = directRiskyTransfer === "N/A" ? 0 : directRiskyTransfer;
  const b = indirectRiskyTransfer === "N/A" ? 0 : indirectRiskyTransfer;
  const ab = a + b;

  const data = {
    labels: ["Direct Risky Transfer", "Indirect Risky Transfer"],
    datasets: [
      {
        label: "Risk Transfer Breakdown",
        data: [(a / ab) * 100, (b / ab) * 100],
        backgroundColor: ["#36A2EB", "#FFCE56"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw as number;
            return value ? `${value.toLocaleString()}%` : "N/A";
          },
        },
      },
    },
  };

  const counterpartyData = biWeeklyDepositTrends
    .filter((record) => record.fields.Page.includes("Top Counterparty;"))
    .filter((item) => item.fields.Key);

  const columns = [
    {
      title: "Category",
      dataIndex: "Key",
      key: "Key",
    },
    {
      title: "Counterparty",
      dataIndex: "Counterparty",
      key: "Counterparty",
    },
    {
      title: "Volume",
      dataIndex: "Value",
      key: "Value",
      render: (value: number) => `$${value.toLocaleString()}`,
    },
  ];

  const counterpartyTableData = counterpartyData.map((record) => ({
    Key: record.fields.Key,
    Counterparty: record.fields.Counterparty,
    Value: record.fields.Value,
  }));

  return (
    <Box className={styles.container} gap={"26px"}>
      <div>
        <Title level={2}>
          Transaction Level Analysis (BTC & ETH) - General
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {renderCard(
              "Total Deposit Volume (USD)",
              totalDepositVolume,
              "N/A"
            )}
          </Col>
          <Col span={12}>
            {renderCard(
              "Total Number of Transactions",
              totalTransactions,
              "N/A",
              () =>
                handleInvolvedAddresses(
                  "Total Number of Transactions (BTC & ETH)",
                  "Last Screen Riskiest Addresses with New Risky Transactions;"
                )
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Title level={2}>
          Transaction Level Analysis (BTC & ETH) - High Risk
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {renderCard(
              "Total Volume of High-Risk transaction",
              highRiskVolume,
              highRiskVolumePercentage
            )}
          </Col>
          <Col span={12}>
            {renderCard(
              "Total Number of High-Risk Transactions",
              highRiskTransactions,
              highRiskTransactionsPercentage,

              () =>
                handleInvolvedAddresses(
                  "Total Number of High-Risk Transactions (BTC & ETH)",
                  "High Risk Transaction Level Analysis (BTC & ETH);"
                )
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Title level={2}>
          Transaction Level Analysis (BTC & ETH) - Direct Risky Transfer VS
          Indirect Risky Transfer
        </Title>
        <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
          <Col md={24} xl={12} xxl={12}>
            <Card className={styles.innerCard}>
              <Title level={4}>Direct VS Indirect Percentage</Title>
              <Box align="center" style={{ maxHeight: 400 }}>
                <Pie data={data} options={options} />
              </Box>
            </Card>
          </Col>
          <Col md={24} lg={24} xl={12} xxl={12}>
            <Box margin={{ top: "48px" }}>
              <Title level={4}>Direct VS Indirect Volume</Title>
            </Box>
            <Row gutter={[0, 24]} style={{ marginBottom: 24, marginTop: 12 }}>
              <Col sm={12} lg={12} xl={24} xxl={12}>
                <ClickableHeader
                  title={"Direct Risky Transfer (USD)"}
                  onClick={() =>
                    handleExpandDetails(
                      "Direct Risky Transfer",
                      "Direct Risk Transfer Exposure to Category Breakdown;"
                    )
                  }
                />
                <Text>
                  {directRiskyTransfer === "N/A"
                    ? "N/A"
                    : directRiskyTransfer.toLocaleString()}
                </Text>
              </Col>
              <Col sm={12} lg={12} xl={24} xxl={12}>
                <ClickableHeader
                  title={"Indirect Risky Transfer (USD)"}
                  onClick={() =>
                    handleExpandDetails(
                      "Indirect Risky Transfer",
                      "Indirect Risk Transfer Exposure to Category Breakdown;"
                    )
                  }
                />
                <Text>
                  {indirectRiskyTransfer === "N/A"
                    ? "N/A"
                    : indirectRiskyTransfer.toLocaleString()}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Title level={2}>
          Top Counterparty In The Past Two Weeks (BTC & ETH)
        </Title>
        <FinancialTable<any> data={counterpartyTableData} columns={columns} />
      </div>
    </Box>
  );
};

export default TransactionLevelAnalysis;
