import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Spin, Card } from "antd";
import { RootState, useAppDispatch } from "store";
import { getNewsDataAction } from "store/news/newsActions";
import { useParams } from "react-router-dom";
import styles from "./news.module.scss";
import "./antOverride.scss";

const NewsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(getNewsDataAction({ baseId: id }));
  }, [dispatch, id]);

  const { loading, error, newsData } = useSelector(
    (state: RootState) => state.news
  );

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const filteredData = newsData.filter(
    (item) => item.fields.Title && item.fields.Link && item.fields.Description
  );

  return (
    <div className={styles.newsContainer}>
      <Row gutter={[24, 24]}>
        {filteredData.map((news) => (
          <Col sm={24} md={12} lg={12} xl={8} xxl={6} key={news.id}>
            <Card
              className={styles.newsCard}
              onClick={() => {
                window.open(news.fields.Link, "_blank");
              }}
              cover={
                <img
                  alt={"News Cover"}
                  src={news.fields.Image}
                  className={styles.newsImage}
                />
              }
            >
              <Card.Meta
                title={news.fields.Title}
                style={{ color: "white" }}
                description={
                  <div className={styles.newsDescription}>
                    {news.fields.Description}
                  </div>
                }
              />

              {news.fields.Date && (
                <div className={styles.newsDate}>{news.fields.Date}</div>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NewsPage;
