import { useLocation, Navigate } from "react-router-dom";
import { useUserContext } from "../../contexts/userProvider";
import { Spin } from "antd";

export function ProtectedRoute({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const { user, loading } = useUserContext();

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
