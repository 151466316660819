import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Input, Row, Col, ConfigProvider } from "antd";
import styles from "./providers.module.scss";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import useClickOutside from "utils/useOutsideClick";

const licenceOptions = [
  "Broker-Dealer Services",
  "Exchange Services (including VA Derivatives Trading)",
  "Management and Investment Services",
  "Lending and Borrowing Services",
  "Exchange Services",
  "Custody Services",
  "Custody Services [including custodial staking]",
];

const Providers = () => {
  const navigate = useNavigate();

  const { providers } = useSelector((state: RootState) => state.providers);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedLicence, setSelectedLicence] = useState<string | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useClickOutside<HTMLDivElement>(() =>
    setDropdownOpen(false)
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleLicenceChange = (value: string | null) => {
    setSelectedLicence(value);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Filter providers based on search term and selected license activity
  const filteredProviders = providers
    .filter((item) => item.fields["Base Id"] && item.fields["Entity Name"])
    .filter((provider) => {
      const matchesSearchTerm = provider.fields["Entity Name"]
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesLicence = selectedLicence
        ? provider.fields["Licence Activities"]?.includes(selectedLicence)
        : true;
      return matchesSearchTerm && matchesLicence;
    });

  // Sort providers by Order field
  const sortedProviders = filteredProviders.sort((a, b) => {
    if (a.fields.Order === undefined) return 1;
    if (b.fields.Order === undefined) return -1;
    return a.fields.Order - b.fields.Order;
  });

  return (
    <div className={styles.container}>
      <div className={styles.layoutConstrain}>
        <div className={styles.searchContainer}>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  activeBg: "#1d1921",
                  activeBorderColor: "#ff00ff",
                  hoverBorderColor: "white",
                  hoverBg: "#1e1e1e",
                  paddingInline: 20,
                },
              },
            }}
          >
            <Input
              placeholder="Search by name, address, symbol"
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
              suffix={<SearchOutlined />}
            />
          </ConfigProvider>
          <div className={styles.dropdownContainer}>
            <div
              className={`${styles.dropdownHeader} ${
                selectedLicence ? styles.active : ""
              }`}
              onClick={toggleDropdown}
              ref={dropdownRef}
            >
              {selectedLicence || "Show All Providers"}
              <DownOutlined className={styles.dropdownIcon} />
            </div>
            {dropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div
                  className={styles.dropdownItem}
                  onClick={() => handleLicenceChange(null)}
                >
                  Show All Providers
                </div>
                {licenceOptions.map((option) => (
                  <div
                    key={option}
                    className={styles.dropdownItem}
                    onClick={() => handleLicenceChange(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Row
          gutter={[20, 20]}
          justify="center"
          style={{ opacity: dropdownOpen ? 0.3 : 1 }}
        >
          {sortedProviders.map((item) => (
            <Col
              key={item.id}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              onClick={() =>
                dropdownOpen
                  ? {}
                  : navigate(`/dashboard/${item.fields["Base Id"]}`)
              }
            >
              <div
                className={`${styles.providerBoxContent} ${
                  dropdownOpen ? "" : styles.allowHover
                }`}
              >
                <div className={styles.logoContainer}>
                  <img
                    className={styles.logo}
                    src={
                      item.fields.Logo && item.fields.Logo.length > 0
                        ? item.fields.Logo[0].url
                        : undefined
                    }
                    alt="logo"
                  />
                </div>
                <b>{item.fields["Trading Name"]}</b>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Providers;
