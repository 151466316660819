import React from "react";
import { Typography } from "antd";
import { ProviderFields } from "store/providers/types";
import FinancialTable from "./../../components/financialTable/financialTable"; // Import the new CustomTable component
import styles from "./insuranceInformation.module.scss";

const { Title } = Typography;

interface InsuranceInformationProps {
  provider: ProviderFields;
}

interface InsuranceData {
  key: number;
  policy: string;
  insurer: string;
  expiry: string;
}

const InsuranceInformation: React.FC<InsuranceInformationProps> = ({
  provider,
}) => {
  const insurancePolicies = provider["Insurance Policies"]?.split(";") || [];
  const insurers = provider.Insurer?.split(";") || [];
  const expiries = provider["Insurance Expiry"]?.split(";") || [];

  const data: InsuranceData[] = insurancePolicies
    .map((policy, index) => ({
      key: index,
      policy,
      insurer: insurers[index],
      expiry: expiries[index],
    }))
    .filter((item) => item.policy);

  const columns = [
    {
      title: "Insurance Policies In Place",
      dataIndex: "policy",
      key: "policy",
    },
    {
      title: "Insurer",
      dataIndex: "insurer",
      key: "insurer",
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
      key: "expiry",
    },
  ];

  return (
    <div className={styles.container}>
      <Title level={2}>Insurance Information</Title>
      <FinancialTable<InsuranceData> data={data} columns={columns} />
    </div>
  );
};

export default InsuranceInformation;
