import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import providersReducer from "./providers/providersReducer";
import supervisionOverviewReducer from "./supervisionOverview/supervisionOverviewReducer";
import standardSupervisionReducer from "./standardSupervision/standardSupervisionReducer";
import monthlyDepositReducer from "./monthlyDeposit/monthlyDepositReducer";
import monthlyDepositTrendsReducer from "./monthlyDepositTrends/monthlyDepositTrendsReducer";
import biWeeklyDepositReducer from "./biWeeklyDeposit/biWeeklyDepositReducer";
import alertSummaryReducer from "./alertSummary/alertSummaryReducer";
import biWeeklyDepositTrendsReducer from "./biWeeklyDepositTrends/biWeeklyDepositTrendsReducer";
import newsReducer from "./news/newsReducer";

export const store = configureStore({
  reducer: {
    providers: providersReducer,
    news: newsReducer,
    supervisionOverview: supervisionOverviewReducer,
    standardSupervision: standardSupervisionReducer,
    monthlyDeposit: monthlyDepositReducer,
    monthlyDepositTrends: monthlyDepositTrendsReducer,
    biWeeklyDeposit: biWeeklyDepositReducer,
    biWeeklyDepositTrends: biWeeklyDepositTrendsReducer,
    alertSummary: alertSummaryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
