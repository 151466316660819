import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getBiWeeklyDepositTrendsDataAction,
  getBiWeeklyDepositTrendsMonitoringChartAction,
} from "./biWeeklyDepositTrendsActions";
import { MonitoringChartPayload } from "./types";
import { TransactionDataPayload } from "../biWeeklyDeposit/types";

type BiWeeklyDepositTrendsState = {
  transactionData: TransactionDataPayload | null;
  monitoringChartData: MonitoringChartPayload | null;
  isLoadingBiWeeklyDepositTrends: boolean;
  isLoadingMonitoringChart: boolean;
  error: string | null;
};

const initialState: BiWeeklyDepositTrendsState = {
  transactionData: null,
  monitoringChartData: null,
  isLoadingBiWeeklyDepositTrends: false,
  isLoadingMonitoringChart: false,
  error: null,
};

const biWeeklyDepositTrendsSlice = createSlice({
  name: "biWeeklyDepositTrends",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBiWeeklyDepositTrendsDataAction.pending, (state) => {
        state.isLoadingBiWeeklyDepositTrends = true;
        state.error = null;
      })
      .addCase(
        getBiWeeklyDepositTrendsDataAction.fulfilled,
        (state, action: PayloadAction<TransactionDataPayload>) => {
          state.transactionData = action.payload;
          state.isLoadingBiWeeklyDepositTrends = false;
        }
      )
      .addCase(getBiWeeklyDepositTrendsDataAction.rejected, (state, action) => {
        state.isLoadingBiWeeklyDepositTrends = false;
        state.error = action.payload
          ? (action.payload as any).error
          : "Failed to fetch transaction data";
      })
      .addCase(
        getBiWeeklyDepositTrendsMonitoringChartAction.pending,
        (state) => {
          state.isLoadingMonitoringChart = true;
          state.error = null;
        }
      )
      .addCase(
        getBiWeeklyDepositTrendsMonitoringChartAction.fulfilled,
        (state, action: PayloadAction<MonitoringChartPayload>) => {
          state.monitoringChartData = action.payload;
          state.isLoadingMonitoringChart = false;
        }
      )
      .addCase(
        getBiWeeklyDepositTrendsMonitoringChartAction.rejected,
        (state, action) => {
          state.isLoadingMonitoringChart = false;
          state.error = action.payload
            ? (action.payload as any).error
            : "Failed to fetch monitoring chart data";
        }
      );
  },
});

export default biWeeklyDepositTrendsSlice.reducer;
