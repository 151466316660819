import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography, Tooltip } from "antd";
import { RootState } from "store";
import { Box } from "grommet";
import styles from "./overview.module.scss";
import ClickableHeader from "components/clickableHeader/clickableHeader";

const { Title, Text } = Typography;

interface OverviewProps {
  handleInvolvedAddresses: (title: string, filterKey: string) => void;
}

const Overview: React.FC<OverviewProps> = ({ handleInvolvedAddresses }) => {
  const { biWeeklyDepositTrends } = useSelector(
    (state: RootState) => state.biWeeklyDeposit
  );

  const getValue = (key: string): number | "N/A" => {
    const record = biWeeklyDepositTrends.find(
      (record) => record.fields.Key === key
    );
    return record
      ? record.fields.Value !== undefined
        ? record.fields.Value
        : "N/A"
      : "N/A";
  };

  const lastScreenedRow = biWeeklyDepositTrends.find(
    (record) => record.fields.Key === "Last Screened"
  );

  const ListedTokenRow = biWeeklyDepositTrends.find(
    (record) => record.fields.Key === "Listed Token"
  );

  const depositVolume = getValue(
    "Riskiest Percentile Addresses 2-Week Deposit Volume"
  );

  return (
    <Box className={styles.container}>
      <Box align="end">
        <div>Last Screened: {lastScreenedRow?.fields.Date || "N/A"}</div>
        <div>
          {ListedTokenRow?.fields.Value
            ? `${ListedTokenRow?.fields.Value}% of tokens are covered.`
            : " "}{" "}
          {ListedTokenRow?.fields.Value && (
            <Tooltip title={ListedTokenRow?.fields.List?.replaceAll(";", ", ")}>
              <u>List of unsupported tokens</u>
            </Tooltip>
          )}
        </div>
      </Box>
      <div>
        <Title level={2}>Summary</Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={5}>
              Riskiest Percentile Addresses: 2-Week Deposit Volume (USD)
            </Title>
            <Text>
              $
              {depositVolume !== "N/A"
                ? Number(depositVolume.toFixed(2)).toLocaleString()
                : depositVolume}
            </Text>
          </Col>
          <Col span={12}>
            <ClickableHeader
              title="Total Number of Riskiest Percentile Addresses"
              onClick={() =>
                handleInvolvedAddresses(
                  "Total Number of Riskiest Percentile Addresses",
                  "Total Number of Riskiest Percentile Addresses;"
                )
              }
            />
            <div className={styles.textContainer}>
              {getValue("Total Number of Riskiest Percentile Addresses")}
            </div>
          </Col>
          <Col span={12}>
            <ClickableHeader
              title="Riskiest Addresses with New Risky Transactions Since Last Screen"
              onClick={() =>
                handleInvolvedAddresses(
                  "Riskiest Addresses with New Risky Transactions Since Last Screen",
                  "Last Screen Riskiest Addresses with New Risky Transactions;"
                )
              }
            />
            <div className={styles.textContainer}>
              {getValue(
                "Last Screen Riskiest Addresses with New Risky Transactions"
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default Overview;
