import React from "react";
import { ConfigProvider, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import styles from "./financialTable.module.scss";

interface CustomTableProps<T> {
  data: T[];
  columns: ColumnsType<T>;
  expandable?: TableProps<T>["expandable"];
  onRow?: TableProps<T>["onRow"];
}

const CustomTable = <T extends object>({
  data,
  columns,
  expandable,
  onRow,
}: CustomTableProps<T>) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerSplitColor: "transparent",
            borderColor: "#9a9a9a75",
            rowHoverBg: "rgba(255, 255, 255, 0.1)",
          },
        },
      }}
    >
      <Table
        className={`${styles.innerCard} ${data.length <= 0 && styles.empty}`}
        dataSource={data}
        columns={columns}
        pagination={false}
        rowClassName={expandable && styles.hoverableTableRow} // Apply row class name
        rowHoverable={expandable ? true : false}
        bordered={false}
        expandable={{ ...expandable, expandIconColumnIndex: -1 }}
        onRow={onRow}
      />
    </ConfigProvider>
  );
};

export default CustomTable;
