import { MouseEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./avatarWithDropdown.module.scss";
import { Box } from "grommet";
import { LogoutOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";
import useClickOutside from "../../utils/useOutsideClick";
import { useUserContext } from "../../contexts/userProvider";
import axios from "axios";
import message from "components/message/message";

function AvatarWithDropdown() {
  const navigate = useNavigate();
  const userContext = useUserContext();

  const [avatarOpen, setAvatarOpen] = useState(false);
  const avatarRef = useClickOutside<HTMLDivElement>(() => setAvatarOpen(false));
  const onAvatarClick = useCallback(() => {
    setAvatarOpen((prev) => !prev);
  }, []);

  const onSignOutClick = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setAvatarOpen(false);

    try {
      const response = await axios.post(
        "https://crosschain-backend.netlify.app/api/users/logout",
        {},
        { withCredentials: true } // Include credentials (cookies)
      );

      if (response.status === 200) {
        message.success("Logout success!");
        userContext.onLogout();
        navigate("/login");
      } else {
        const errorMessage =
          response.data?.message || "Logout failed. Please try again.";

        message.error(errorMessage);
      }
    } catch (error: any) {
      const errorMessage =
        error.response.data || "Logout error. Please try again.";

      message.error(errorMessage);
    }
  };

  const onChangePasswordClick = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setAvatarOpen(false);
    navigate("change-password");
  };

  return (
    <div className={styles["root"]} onClick={onAvatarClick} ref={avatarRef}>
      <Box direction="row" gap={"8px"} align="center" justify="center">
        <div className={styles["avatar"]}>
          <UserOutlined />
        </div>
        <div className={styles["hi-message"]}>Hi, User</div>
      </Box>
      {avatarOpen ? (
        <div className={styles["dropdown-root"]}>
          <div
            onClick={onChangePasswordClick}
            className={`${styles["item"]} ${styles["item"]}`}
          >
            <Box direction="row" gap="8px">
              <KeyOutlined />
              <div>Change Password</div>
            </Box>
          </div>
          <div
            onClick={onSignOutClick}
            className={`${styles["item"]} ${styles["item"]}`}
          >
            <Box direction="row" gap="8px">
              <LogoutOutlined />
              <div>Logout</div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AvatarWithDropdown;
