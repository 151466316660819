import React from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  ConfigProvider,
  Tooltip as AntTooltip,
} from "antd";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ProviderFields } from "store/providers/types";
import styles from "./assetsLiabilitiesInfo.module.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Box } from "grommet";

ChartJS.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

const { Title, Text } = Typography;

interface AssetsLiabilitiesInfoProps {
  provider: ProviderFields;
  vaTrackerWarning: boolean;
  fiatTrackerWarning: boolean;
  fiatAndVATrackerWarning: boolean;
}

const formatNumber = (num: number) => {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "K";
  }
  return num.toString();
};

const AssetsLiabilitiesInfo: React.FC<AssetsLiabilitiesInfoProps> = ({
  provider,
  vaTrackerWarning,
  fiatTrackerWarning,
  fiatAndVATrackerWarning,
}) => {
  const renderBarChart = (
    title: string,
    data: number[],
    labels: string[],
    legendData: { label: string; value: number }[],
    showWarning: boolean,
    forceNA?: boolean
  ) => {
    const colors = ["#ef25ae", "#29f7f7"];
    const total = data.reduce((sum, value) => sum + value, 0);

    return (
      <ConfigProvider
        theme={{
          components: {
            Card: {
              headerFontSize: 24,
            },
          },
        }}
      >
        <Card className={styles.innerCard}>
          <div style={{ marginBottom: 24 }}>
            <Box direction="row" align="center">
              <Title level={3}>{title}</Title>
              {showWarning && (
                <AntTooltip title={"Assets and Liability imbalanced!"}>
                  <ExclamationCircleOutlined
                    style={{
                      color: "red",
                      marginLeft: "8px",
                      fontSize: 20,
                    }}
                  />
                </AntTooltip>
              )}
            </Box>
          </div>
          {data.filter((item) => item).length > 0 && forceNA !== true ? (
            <Row gutter={[36, 16]} justify="center">
              <Col xs={24} xl={24} xxl={12}>
                <div className={styles.chartContainer}>
                  <Bar
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          label: title,
                          data: data,
                          backgroundColor: colors,
                          barThickness: 30, // Increase bar thickness
                        },
                      ],
                    }}
                    options={{
                      indexAxis: "y",
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              const value = tooltipItem.raw as number;
                              const percentage = (
                                (value / total) *
                                100
                              ).toFixed(2);
                              return value
                                ? `$${value.toLocaleString()} (${percentage}%)`
                                : "N/A";
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: true,
                          color: "#fff",
                          anchor: "end",
                          align: "end",
                          formatter: formatNumber, // Format numbers
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          beginAtZero: true,
                          grid: {
                            display: true,
                            color: "rgba(255,255,255,0.1)", // Background grid color
                          },
                          ticks: {
                            callback: function (value) {
                              return formatNumber(value as number); // Format axis labels
                            },
                          },
                        },
                      },
                      layout: {
                        padding: {
                          left: 10,
                          right: 10,
                          top: 10,
                          bottom: 10,
                        },
                      },
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} xl={24} xxl={12}>
                <div className={styles.legendContainer}>
                  {legendData.map((item, index) => (
                    <div className={styles.legendItem} key={index}>
                      <div
                        className={styles.legendColor}
                        style={{ backgroundColor: colors[index] }}
                      ></div>
                      <Text className={styles.legendLabel}>{item.label}</Text>
                      <Text className={styles.legendValue}>
                        ${item.value?.toLocaleString() || "N/A"} (
                        {((item.value / total) * 100).toFixed(2)}%)
                      </Text>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          ) : (
            <div className={styles.noData}>{"No Data"}</div>
          )}
        </Card>
      </ConfigProvider>
    );
  };

  const fiatVsVaAssets =
    (provider["Total Customer VA assets"] || 0) +
    (provider["Total Customer Fiat assets"] || 0);
  const fiatVsVaLiabilities =
    (provider["Total Customer VA Liabilities"] || 0) +
    (provider["Total Customer Fiat Liabilities"] || 0);
  const fiatAssets = provider["Total Customer Fiat assets"] || 0;
  const fiatLiabilities = provider["Total Customer Fiat Liabilities"] || 0;
  const vaAssets = provider["Total Customer VA assets"] || 0;
  const vaLiabilities = provider["Total Customer VA Liabilities"] || 0;

  return (
    <div className={styles.container}>
      <Title level={2}>Assets and Liabilities Information</Title>
      <Row gutter={[16, 16]}>
        <Col md={24} lg={12}>
          {renderBarChart(
            "Fiat & VA Tracker (AED)",
            [fiatVsVaAssets, fiatVsVaLiabilities],
            ["Assets", "Liabilities"],
            [
              { label: "Assets", value: fiatVsVaAssets },
              { label: "Liabilities", value: fiatVsVaLiabilities },
            ],
            fiatAndVATrackerWarning,
            !fiatAssets || !vaAssets
          )}
        </Col>
        <Col md={24} lg={12}>
          {renderBarChart(
            "Fiat Tracker (AED)",
            [fiatAssets, fiatLiabilities],
            ["Assets", "Liabilities"],
            [
              { label: "Assets", value: fiatAssets },
              { label: "Liabilities", value: fiatLiabilities },
            ],
            fiatTrackerWarning
          )}
        </Col>
        <Col md={24} lg={12}>
          {renderBarChart(
            "VA Tracker (AED)",
            [vaAssets, vaLiabilities],
            ["Assets", "Liabilities"],
            [
              { label: "Assets", value: vaAssets },
              { label: "Liabilities", value: vaLiabilities },
            ],
            vaTrackerWarning
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AssetsLiabilitiesInfo;
