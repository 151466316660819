enum Tabs {
  key_info = "key_info",
  news = "news",
  financial_soundness = "financial_soundness",
  standard_supervision = "standard_supervision",
  monthly_deposit = "monthly_deposit",
  monthly_deposit_trends = "monthly_deposit_trends",
  biweekly_deposit = "biweekly_deposit",
  biweekly_deposit_trends = "biweekly_deposit_trends",
  alert_summary = "alert_summary",
}

export default Tabs;
