import React from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import styles from "./financialStatistic.module.scss";
import { Box } from "grommet";

const FinancialStatistic = ({ value }: { value?: number }) => (
  <div className={styles.statisticValue}>
    {value !== undefined ? (
      value === 0 ? (
        <div className={`${styles.content}`}>0%</div>
      ) : (
        <Box
          direction="row"
          align="center"
          justify="start"
          className={`${styles.content} ${
            value > 0 ? styles.contentGreen : styles.contentRed
          }`}
        >
          <span className={styles.prefix}>
            {value > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </span>
          {value.toFixed(2)}%
        </Box>
      )
    ) : (
      "N/A"
    )}
  </div>
);

export default FinancialStatistic;
