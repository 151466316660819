import styles from "./navBar.module.scss";
import { FC, memo } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import AvatarWithDropdown from "./avatarWithDropdown";
import useFetchProviders from "./useFetchProviders";
import routes from "constants/routes";
import { Box } from "grommet";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const NavBar: FC = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const provider = useSelector((state: RootState) =>
    state.providers.providers.find((provider) => {
      return (
        provider.fields["Base Id"] !== undefined &&
        provider.fields["Base Id"] === id
      );
    })
  )?.fields;
  return (
    <div className={styles["root"]}>
      <div
        className={styles["left-container"]}
        onClick={() => {
          navigate(routes.providers);
        }}
      >
        <Logo className={styles.logo} />
      </div>
      <Box className={styles["right-container"]} direction="row" gap={"20px"}>
        {provider?.Logo && provider?.Logo.length > 0 ? (
          <img
            className={styles.providerLogo}
            alt={"logo"}
            src={provider?.Logo[0].url}
          />
        ) : (
          <div>{provider?.["Entity Name"]}</div>
        )}
        {location.pathname !== "/dashboard/providers" &&
          location.pathname !== "/dashboard/change-password" && (
            <div className={styles.separator}></div>
          )}
        <AvatarWithDropdown />
      </Box>
    </div>
  );
});

const NavBarWithOutlet: FC = () => {
  useFetchProviders();

  return (
    <div className={styles["with-outlet-container"]}>
      <NavBar />
      <Outlet />
    </div>
  );
};

export default NavBarWithOutlet;
