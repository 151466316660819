import React from "react";
import { Box } from "grommet";
import { Typography } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import styles from "./clickableHeader.module.scss"; // Make sure to create this CSS module

const { Title } = Typography;

interface ClickableHeaderProps {
  title: string;
  onClick: () => void;
  forGraph?: boolean;
}

const ClickableHeader: React.FC<ClickableHeaderProps> = ({
  title,
  onClick,
  forGraph = false,
}) => {
  return (
    <Box className={styles.clickableHeader} onClick={onClick}>
      <Title
        level={forGraph ? 4 : 5}
        style={{ display: "flex", alignItems: "center" }}
      >
        {title}
        <DoubleRightOutlined
          style={
            forGraph
              ? { width: 32, height: 32, marginLeft: 2 }
              : { width: 18, marginLeft: 2 }
          }
        />
      </Title>
    </Box>
  );
};

export default ClickableHeader;
