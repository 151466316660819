import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Typography, Tooltip, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getStandardSupervisionData } from "store/standardSupervision/standardSupervisionActions";
import { RootState, AppDispatch } from "store";
import { useParams } from "react-router-dom";
import styles from "./standardSupervision.module.scss";
import { Box } from "grommet";
import FinancialTable from "components/financialTable/financialTable";

const { Title } = Typography;

const StandardSupervision: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { records, isLoading } = useSelector(
    (state: RootState) => state.standardSupervision
  );

  useEffect(() => {
    if (!id) return;
    dispatch(getStandardSupervisionData({ baseId: id }));
  }, [dispatch, id]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const renderTables = (section: string, noExpired?: boolean) => {
    const columns = [
      {
        title: "Regulatory Report Name",
        dataIndex: "Key",
        key: "Key",
        render: (text: string, record: any) => (
          <>
            {text}
            {record.isAlert && (
              <Tooltip title={`${record["Alert Message"]}`}>
                <ExclamationCircleOutlined
                  style={{ color: "red", marginLeft: 8 }}
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: "Last Update",
        dataIndex: "Last Update",
        key: "Last Update",
      },
      {
        title: "Due Date",
        dataIndex: "Due Date",
        key: "Due Date",
        render: (text: string) => <>{text || "N/A"}</>,
      },
    ];

    const col = noExpired ? columns.slice(0, 2) : columns;

    const data = records
      .filter((record) => record.fields.Section === section)
      .map((record, index) => ({
        key: index,
        Key: record.fields.Key,
        "Last Update": record.fields["Last Update"] || "N/A",
        "Due Date": record.fields["Due Date"] || "N/A",
        isAlert: record.fields.isAlert,
        "Alert Message":
          record.fields["Alert Message"]?.trim() || "Something is abnormal!",
      }));

    return <FinancialTable<any> data={data} columns={col} />;
  };

  const alertCount = records.filter((record) => record.fields.isAlert).length;

  return (
    <div className={styles.container}>
      <Box direction="row" justify="end">
        <div className={alertCount ? styles.alert : styles.noAlert}>
          Alerts on this page: {alertCount}
        </div>
      </Box>

      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Title level={2}>Prior to Occurrence</Title>
          {renderTables("Prior to Occurence", true)}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Upon Occurrence</Title>
          {renderTables("Upon Occurrence", true)}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Daily</Title>
          {renderTables("Daily")}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Weekly</Title>
          {renderTables("Weekly")}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Monthly</Title>
          {renderTables("Monthly")}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Quarterly</Title>
          {renderTables("Quarterly")}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Bi-Annual</Title>
          {renderTables("Bi-Annual")}
        </Col>
        <Col xs={24} xl={12}>
          <Title level={2}>Annual</Title>
          {renderTables("Annual")}
        </Col>
      </Row>
    </div>
  );
};

export default StandardSupervision;
