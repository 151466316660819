import { createSlice } from "@reduxjs/toolkit";
import { getAlertSummaryDataAction } from "./alertSummaryActions";
import { AlertSummaryState } from "./types";

const initialState: AlertSummaryState = {
  alertSummaryData: [],
  isLoadingAlertSummary: false,
};

const alertSummarySlice = createSlice({
  name: "alertSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAlertSummaryDataAction.pending, (state) => {
      state.isLoadingAlertSummary = true;
    });
    builder.addCase(getAlertSummaryDataAction.fulfilled, (state, action) => {
      state.alertSummaryData = action.payload.alertSummaryData;
      state.isLoadingAlertSummary = false;
    });
    builder.addCase(getAlertSummaryDataAction.rejected, (state) => {
      state.isLoadingAlertSummary = false;
    });
  },
});

export default alertSummarySlice.reducer;
