import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getBiWeeklyDepositDataAction,
  getBiWeeklyDepositInvolvedAddressesAction,
} from "./biWeeklyDepositActions";
import {
  TransactionDataPayload,
  BiWeeklyDepositState,
  InvolvedAddressesPayload,
  InvolvedAddressesState,
} from "./types";

const initialState: BiWeeklyDepositState & InvolvedAddressesState = {
  biWeeklyDepositTrends: [],
  involvedAddresses: [],
  isLoadingBiWeeklyDepositTrends: false,
  isLoadingInvolvedAddresses: false,
};

const biWeeklyDepositSlice = createSlice({
  name: "biWeeklyDeposit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBiWeeklyDepositDataAction.pending, (state) => {
        state.isLoadingBiWeeklyDepositTrends = true;
      })
      .addCase(
        getBiWeeklyDepositDataAction.fulfilled,
        (state, action: PayloadAction<TransactionDataPayload>) => {
          state.isLoadingBiWeeklyDepositTrends = false;
          state.biWeeklyDepositTrends = action.payload.transactionData;
        }
      )
      .addCase(getBiWeeklyDepositDataAction.rejected, (state) => {
        state.isLoadingBiWeeklyDepositTrends = false;
      })
      .addCase(getBiWeeklyDepositInvolvedAddressesAction.pending, (state) => {
        state.isLoadingInvolvedAddresses = true;
      })
      .addCase(
        getBiWeeklyDepositInvolvedAddressesAction.fulfilled,
        (state, action: PayloadAction<InvolvedAddressesPayload>) => {
          state.isLoadingInvolvedAddresses = false;
          state.involvedAddresses = action.payload.involvedAddressesData;
        }
      )
      .addCase(getBiWeeklyDepositInvolvedAddressesAction.rejected, (state) => {
        state.isLoadingInvolvedAddresses = false;
      });
  },
});

export default biWeeklyDepositSlice.reducer;
