import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NewsState, NewsPayload } from "./types";
import { getNewsDataAction } from "./newsActions";

const initialState: NewsState = {
  loading: false,
  error: null,
  newsData: [],
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsDataAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getNewsDataAction.fulfilled,
        (state, action: PayloadAction<NewsPayload>) => {
          state.loading = false;
          state.newsData = action.payload.newsData;
        }
      )
      .addCase(getNewsDataAction.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as any)?.error ?? "Failed to fetch news data";
      });
  },
});

export default newsSlice.reducer;
