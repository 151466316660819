import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Typography } from "antd";
import FinancialChart from "components/financialChart/financialChart"; // Adjust the import path as needed
import { TransactionChartDataRecord } from "store/monthlyDepositTrends/types";
import { severityOrder } from "utils/constants";
import styles from "./monthlyDepositTrends.module.scss";
import FinancialTable from "components/financialTable/financialTable";
import { Box } from "grommet";
import { ExpandAltOutlined } from "@ant-design/icons";

const { Title } = Typography;

const DepositExposureToCategoryBreakdown: React.FC = () => {
  const { depositExposureCategoryBreakdown, monthlyDepositCharts } =
    useSelector((state: RootState) => state.monthlyDepositTrends);

  const filteredCharts = monthlyDepositCharts.filter((chart) =>
    chart.fields.Page.includes("Monthly Volume per Category;")
  );

  const [expandedRowKey, setExpandedRowKey] = useState<React.Key>();

  const handleExpand = (record: any) => {
    setExpandedRowKey(expandedRowKey === record.key ? null : record.key);
  };

  const getCategoryData = (
    categoryName: string
  ): TransactionChartDataRecord | undefined => {
    return filteredCharts.find((chart) => chart.fields.Name === categoryName);
  };

  const renderChartData = (categoryName: string, takeAllSpace?: boolean) => {
    const chartData = getCategoryData(categoryName);
    if (!chartData || !chartData.fields.Value) {
      return null;
    }

    const valueArray = chartData.fields.Value.toString().split(";").map(Number);
    const lastUpdateMonth = chartData.fields["Last Update Month"];

    return (
      <div>
        <Box
          justify="center"
          align="center"
          style={{
            ...(takeAllSpace ? { height: 600, maxHeight: 600 } : {}),
            padding: 34,
            paddingTop: 0,
          }}
        >
          <Title level={5}>
            Monthly Volume per Category ({expandedRowKey as string})
          </Title>
          <FinancialChart
            chartData={valueArray}
            lastUpdateMonth={lastUpdateMonth!}
            label={`${categoryName} Volume`}
            takeAllSpace={takeAllSpace}
          />
        </Box>
      </div>
    );
  };

  const columns = [
    {
      title: "Severity",
      dataIndex: ["fields", "Severity"],
      key: "severity",
    },
    {
      title: "Category",
      dataIndex: ["fields", "Key"],
      key: "category",
    },
    {
      title: "Volume Change - Percentage",
      dataIndex: ["fields", "Percentage"],
      key: "volumeChangePercentage",
      render: (text: number) => {
        if (text === undefined) {
          return <span>{"No exposure to this category last month"}</span>;
        }
        return (
          <span>
            {text ? `${parseFloat(text.toString()).toLocaleString()}%` : "-"}
          </span>
        );
      },
    },
    {
      title: "Volume Change - Amount (USD)",
      dataIndex: ["fields", "Value"],
      key: "volumeChangeAmount",
      render: (text: number) => (
        <span>
          {text ? `$${parseFloat(text.toString()).toLocaleString()}` : "0"}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: ["fields", "Key"],
      key: "expendable",
      render: () => <ExpandAltOutlined />,
    },
  ];

  const dataSource = depositExposureCategoryBreakdown
    .map((record) => ({
      ...record,
      key: record.fields.Key, // Ensure each record has a unique key
      fields: {
        ...record.fields,
        Key: record.fields.Key || "Unknown Category",
      },
    }))
    .sort((a, b) => {
      const severityA =
        severityOrder[a.fields.Severity as keyof typeof severityOrder];
      const severityB =
        severityOrder[b.fields.Severity as keyof typeof severityOrder];
      if (severityA !== severityB) {
        return severityA - severityB;
      }
      return (a.fields.Order || 0) - (b.fields.Order || 0);
    });

  return (
    <div className={styles.container}>
      <Box gap="4px">
        <Title level={2}>Deposit Exposure to Category Breakdown</Title>
        <FinancialTable<any>
          data={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: () =>
              renderChartData(
                filteredCharts.find(
                  (item) => item.fields.Name === expandedRowKey
                )?.fields.Name || "",
                true
              ),
            rowExpandable: () => true,
            expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
          }}
          onRow={(record) => ({
            onClick: () => handleExpand(record),
          })}
        />
      </Box>
    </div>
  );
};

export default DepositExposureToCategoryBreakdown;
