import { createSlice } from "@reduxjs/toolkit";
import { getStandardSupervisionData } from "./standardSupervisionActions";
import { StandardSupervisionRecord } from "./types";

export interface StandardSupervisionState {
  records: StandardSupervisionRecord[];
  isLoading: boolean;
}

const initialState: StandardSupervisionState = {
  records: [],
  isLoading: false,
};

export const standardSupervisionSlice = createSlice({
  name: "standardSupervision",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStandardSupervisionData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStandardSupervisionData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.records = action.payload.records;
      })
      .addCase(getStandardSupervisionData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default standardSupervisionSlice.reducer;
