import { message } from "antd";
import React from "react";
import "./message.scss"; // Import the custom CSS

const CustomMessage = {
  success: (content: React.ReactNode, duration?: number) => {
    message.success({
      content,
      duration,
      className: "custom-message",
    });
  },
  error: (content: React.ReactNode, duration?: number) => {
    message.error({
      content,
      duration,
      className: "custom-message",
    });
  },
  info: (content: React.ReactNode, duration?: number) => {
    message.info({
      content,
      duration,
      className: "custom-message",
    });
  },
  warning: (content: React.ReactNode, duration?: number) => {
    message.warning({
      content,
      duration,
      className: "custom-message",
    });
  },
  loading: (content: React.ReactNode, duration?: number) => {
    message.loading({
      content,
      duration,
      className: "custom-message",
    });
  },
};

export default CustomMessage;
