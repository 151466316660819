import React from "react";
import { Modal, Table } from "antd";
import { InvolvedAddressesRecord } from "store/biWeeklyDeposit/types";
import { severityOrder } from "utils/constants";

interface InvolvedAddressModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: InvolvedAddressesRecord[];
  title: string;
}

const InvolvedAddressModal: React.FC<InvolvedAddressModalProps> = ({
  isVisible,
  onClose,
  data,
  title,
}) => {
  const columns = [
    {
      title: "Severity",
      dataIndex: ["fields", "Severity"],
      key: "Severity",
    },
    {
      title: "Category",
      dataIndex: ["fields", "Category"],
      key: "Category",
    },
    {
      title: "Addresses",
      dataIndex: ["fields", "Addresses"],
      key: "Addresses",
    },
    {
      title: "USD",
      dataIndex: ["fields", "Amount"],
      key: "Value",
      render: (value: number) =>
        value !== undefined
          ? `$${Number(value.toFixed(2)).toLocaleString()}`
          : "N/A",
    },
  ];

  const sortedData = data.sort((a, b) => {
    const severityA =
      severityOrder[a.fields.Severity as keyof typeof severityOrder];
    const severityB =
      severityOrder[b.fields.Severity as keyof typeof severityOrder];
    return severityA - severityB;
  });

  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
        dataSource={sortedData}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};

export default InvolvedAddressModal;
