export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const severityOrder = {
  Severe: 1,
  High: 2,
  Medium: 3,
  Low: 4,
};
