import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import EntityOverview from "./entityOverview";
import AuditInformation from "./auditInformation";
import AssetsLiabilitiesInfo from "./assetsLiabilitiesInfo";
import InsuranceInformation from "./insuranceInformation";
import BankInformation from "./bankInformation";
import { Spin } from "antd";
import { Box } from "grommet";

const KeyInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const provider = useSelector((state: RootState) =>
    state.providers.providers.find(
      (provider) => provider.fields["Base Id"] === id
    )
  )?.fields;

  const { isLoading } = useSelector((state: RootState) => state.providers);

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (!provider) {
    return <div>Provider not found</div>;
  }

  const warningCount =
    (provider["Fiat & VA Alert"] ? 1 : 0) +
    (provider["VA Alert"] ? 1 : 0) +
    (provider["Fiat Alert"] ? 1 : 0);

  return (
    <div>
      <Box gap={"48px"}>
        <EntityOverview provider={provider} warningCount={warningCount} />
        <Box gap={"48px"}>
          <AssetsLiabilitiesInfo
            provider={provider}
            vaTrackerWarning={provider["VA Alert"] || false}
            fiatTrackerWarning={provider["Fiat Alert"] || false}
            fiatAndVATrackerWarning={provider["Fiat & VA Alert"] || false}
          />
          {id !== "app7eBzFnPP4uIkKz" && (
            <AuditInformation provider={provider} />
          )}
          {id !== "app7eBzFnPP4uIkKz" && (
            <InsuranceInformation provider={provider} />
          )}
          {id !== "app7eBzFnPP4uIkKz" && (
            <BankInformation provider={provider} />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default KeyInfo;
