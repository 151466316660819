import React, { useEffect } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getSupervisionOverviewAction } from "store/supervisionOverview/supervisionOverviewActions";
import FinancialSummary from "./financialSummary";
import { useParams } from "react-router-dom";

const Supervision: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    dispatch(getSupervisionOverviewAction({ baseId: id }));
  }, [dispatch, id]);

  const isLoading = useSelector(
    (state: RootState) => state.supervisionOverview.isLoading
  );

  if (isLoading) {
    return <Spin size="large" />;
  }

  return <FinancialSummary />;
};

export default Supervision;
