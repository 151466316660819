import { createAsyncThunk } from "@reduxjs/toolkit";
import AirtableApiClient from "../../utils/airtableApiClient";
import {
  TransactionDataPayload,
  InvolvedAddressesPayload,
  TransactionDataRecord,
  InvolvedAddressesRecord,
} from "./types";

export const getBiWeeklyDepositDataAction = createAsyncThunk<
  TransactionDataPayload,
  { baseId: string },
  {}
>("biWeeklyDeposit/get", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const transactionData =
      await AirtableApiClient.fetchAllRecords<TransactionDataRecord>(
        client,
        "Transaction Monitoring?view=Master view&filterByFormula=FIND(%22Bi+Weekly+Screening;%22%2C+%7BPage%7D)"
      );
    return { transactionData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});

export const getBiWeeklyDepositInvolvedAddressesAction = createAsyncThunk<
  InvolvedAddressesPayload,
  { baseId: string },
  {}
>("biWeeklyDeposit/getInvolvedAddresses", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const involvedAddressesData =
      await AirtableApiClient.fetchAllRecords<InvolvedAddressesRecord>(
        client,
        "Involved Address?view=Master view&filterByFormula=FIND(%22Bi+Weekly+Screening;%22%2C+%7BPage%7D)"
      );
    return { involvedAddressesData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
