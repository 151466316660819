// DepositExposureToRiskBreakdown.tsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Card, Row, Col, Spin, Typography, Button } from "antd";
import FinancialChart from "components/financialChart/financialChart";
import { TransactionChartDataRecord } from "store/monthlyDepositTrends/types";
import FinancialStatistic from "components/financialStatistic/financialStatistic";
import { Box } from "grommet";
import styles from "./monthlyDepositTrends.module.scss";

const { Title } = Typography;
const DepositExposureToRiskBreakdown: React.FC = () => {
  const {
    monthlyDepositTrends,
    monthlyDepositCharts,
    isLoadingDepositExposureCategoryBreakdown,
    isLoadingMonthlyDepositCharts,
  } = useSelector((state: RootState) => state.monthlyDepositTrends);

  const [selectedChart, setSelectedChart] = useState<string>("Severe Risk");

  const getChartData = () => {
    const chartData: TransactionChartDataRecord | undefined =
      monthlyDepositCharts.find(
        (item) =>
          item.fields.Name === selectedChart &&
          item.fields.Page.includes("Monthly Volume by Severity Level;")
      );

    if (!chartData) {
      return null;
    }

    const valueArray =
      chartData.fields.Value?.toString().split(";").map(Number) || [];
    const lastUpdateMonth = chartData.fields["Last Update Month"];

    return { valueArray, lastUpdateMonth };
  };

  if (
    isLoadingDepositExposureCategoryBreakdown ||
    isLoadingMonthlyDepositCharts
  ) {
    return <Spin size="large" />;
  }

  const chartData = getChartData();

  const getValue = (key: string) => {
    const target = monthlyDepositTrends?.find(
      (item) => item.fields.Key === key
    );
    return target?.fields.Value;
  };

  return (
    <Box className={styles.container}>
      <Title level={2}>Deposit Exposure to Risk Breakdown</Title>
      <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
        <Col sm={24} xxl={12}>
          <Card className={styles.innerCard}>
            <div className={styles.chartHeader}>
              <Title level={4}>{selectedChart} Volume</Title>
              <Box direction="row" gap={"8px"}>
                {monthlyDepositCharts
                  .filter((item) =>
                    item.fields.Page.includes(
                      "Monthly Volume by Severity Level;"
                    )
                  )
                  .map((item) => (
                    <Button
                      key={item.fields.Name}
                      className={`${styles.graphTab} ${
                        selectedChart === item.fields.Name && styles.selected
                      }`}
                      onClick={() => setSelectedChart(item.fields.Name)}
                    >
                      {item.fields.Name}
                    </Button>
                  ))}
              </Box>
            </div>
            {chartData && (
              <FinancialChart
                chartData={chartData.valueArray}
                lastUpdateMonth={chartData.lastUpdateMonth!}
                label={`${selectedChart} Volume`}
              />
            )}
          </Card>
        </Col>
        <Col sm={24} xxl={12}>
          <Box margin={{ top: "48px" }}>
            <Title level={4}>Monthly Volume by Severity Level</Title>
          </Box>
          <Row gutter={[0, 24]}>
            {[
              {
                label: "Severe Risk Volume",
                key: "Severe Risk Deposit Exposure to Risk Breakdown",
              },
              {
                label: "High Risk Volume",
                key: "High Risk Deposit Exposure to Risk Breakdown",
              },
              {
                label: "Medium Risk Volume",
                key: "Medium Risk Deposit Exposure to Risk Breakdown",
              },
              {
                label: "Low Risk Volume",
                key: "Low Risk Deposit Exposure to Risk Breakdown",
              },
            ].map(({ label, key }) => (
              <Col md={6} xxl={12} key={key}>
                <Title level={5}>{label}</Title>
                <FinancialStatistic value={getValue(key)} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Box>
  );
};

export default DepositExposureToRiskBreakdown;
