import {
  MonitorOutlined,
  WarningOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Menu } from "antd";
import { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "../../constants/tabs"; // Assuming Tabs is imported from the correct path
import { useSelector } from "react-redux";
import { RootState } from "store";

export interface MenuItem {
  key: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  children?: MenuItem[];
}

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { id, tabName } = useParams<{ id: string; tabName?: string }>();

  const provider = useSelector((state: RootState) =>
    state.providers.providers.find(
      (provider) => provider.fields["Base Id"] === id
    )
  )?.fields;

  const disableBiWeekly = provider ? provider["disableBiWeekly"] : false;
  const disableBiWeeklyTrend = provider
    ? provider["disableBiWeeklyTrend"]
    : false;

  const items: MenuItem[] = [
    {
      key: "Overview",
      label: "Overview",
      icon: <PieChartOutlined />,
      children: [
        {
          key: Tabs.key_info,
          label: "Key Info",
        },
        {
          key: Tabs.news,
          label: "News",
        },
      ],
    },
    {
      key: "Supervision",
      label: "Supervision",
      icon: <MonitorOutlined />,
      children: [
        {
          key: Tabs.financial_soundness,
          label: "Financial Soundness",
        },
        {
          key: Tabs.standard_supervision,
          label: "Standard Supervision",
          disabled: id === "app7eBzFnPP4uIkKz",
        },
      ],
    },
    {
      key: "Transaction Monitoring",
      label: "Transaction Monitoring",
      icon: <MonitorOutlined />,
      children: [
        {
          key: Tabs.monthly_deposit,
          label: "Monthly",
        },
        {
          key: Tabs.monthly_deposit_trends,
          label: "Monthly Trends",
        },
        {
          key: Tabs.biweekly_deposit,
          label: "Biweekly",
          disabled: disableBiWeekly, // Disable based on the flag
        },
        {
          key: Tabs.biweekly_deposit_trends,
          label: "Biweekly Trends",
          disabled: disableBiWeeklyTrend, // Disable based on the flag
        },
      ],
    },
    {
      key: Tabs.alert_summary,
      icon: <WarningOutlined />,
      label: "Alert Summary",
    },
  ];

  const getOpenKeys = () => {
    for (const item of items) {
      if (item.children) {
        for (const child of item.children) {
          if (child.key === tabName) {
            return [item.key];
          }
        }
      }
    }
    return [items[0].key]; // open the first group by default
  };

  const selectedTab = tabName || Tabs.key_info;

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="demo-logo-vertical" />
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemSelectedBg: "#de219a !important",
            },
          },
        }}
      >
        <Menu
          theme="dark"
          selectedKeys={[selectedTab]}
          defaultOpenKeys={getOpenKeys()}
          mode="inline"
          items={items}
          onClick={(tab) => {
            navigate(`/dashboard/${id}/${tab.key}`);
          }}
        />
      </ConfigProvider>
    </Sider>
  );
};

export default SideBar;
