import React from "react";
import { Row, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styles from "./riskProfileAndBreakdown.module.scss";
import { Box } from "grommet";
import ClickableHeader from "../../components/clickableHeader/clickableHeader";
import PercentageBarChart from "../../components/percentageBarChart/percentageBarChart";

const { Title, Text } = Typography;

interface RiskProfileAndBreakdownProps {
  handleExpandDetails: (title: string, filterKey: string) => void;
  handleInvolvedAddresses: (title: string, filterKey: string) => void;
}

const RiskProfileAndBreakdown: React.FC<RiskProfileAndBreakdownProps> = ({
  handleExpandDetails,
  handleInvolvedAddresses,
}) => {
  const biWeeklyDepositTrends = useSelector(
    (state: RootState) => state.biWeeklyDeposit.biWeeklyDepositTrends
  );

  const getValue = (key: string): number | "N/A" => {
    const record = biWeeklyDepositTrends.find(
      (record) => record.fields.Key === key
    );
    return record
      ? record.fields.Value !== undefined
        ? record.fields.Value
        : 0
      : "N/A";
  };

  const renderCard = (
    title: string,
    value: number | "N/A",
    buttonClickHandler?: () => void
  ) => {
    const isMoney = title.includes("USD");
    return (
      <>
        {buttonClickHandler ? (
          <ClickableHeader title={title} onClick={buttonClickHandler} />
        ) : (
          <Title level={5}>{title}</Title>
        )}
        <Text style={{ textAlign: "left", display: "block" }}>
          {isMoney ? "$" : ""}
          {value.toLocaleString()}
        </Text>
      </>
    );
  };

  const severeRiskValue = getValue(
    "Severe Risk Deposit Exposure to Risk Breakdown"
  );
  const highRiskValue = getValue(
    "High Risk Deposit Exposure to Risk Breakdown"
  );
  const mediumRiskValue = getValue(
    "Medium Risk Deposit Exposure to Risk Breakdown"
  );
  const lowRiskValue = getValue("Low Risk Deposit Exposure to Risk Breakdown");

  const severeAddressValue = getValue("Number of Severe Risk Addresses");
  const highAddressValue = getValue("Number of High Risk Addresses");
  const mediumAddressValue = getValue("Number of Medium Risk Addresses");
  const lowAddressValue = getValue("Number of Low Risk Addresses");

  const riskValues = [
    severeRiskValue === "N/A" ? 0 : severeRiskValue,
    highRiskValue === "N/A" ? 0 : highRiskValue,
    mediumRiskValue === "N/A" ? 0 : mediumRiskValue,
    lowRiskValue === "N/A" ? 0 : lowRiskValue,
  ];

  const addressValues = [
    severeAddressValue === "N/A" ? 0 : severeAddressValue,
    highAddressValue === "N/A" ? 0 : highAddressValue,
    mediumAddressValue === "N/A" ? 0 : mediumAddressValue,
    lowAddressValue === "N/A" ? 0 : lowAddressValue,
  ];

  const totalRiskValue = riskValues.reduce((acc, val) => acc + val, 0);
  const totalAddressValue = addressValues.reduce((acc, val) => acc + val, 0);

  const calculatePercentage = (value: number | "N/A") => {
    if (typeof value === "number") {
      return (value / totalRiskValue) * 100;
    }
    return 0;
  };

  const getAddressPercentage = (value: number | "N/A") => {
    if (typeof value === "number") {
      return (value / totalAddressValue) * 100;
    }
    return 0;
  };

  const severeRiskPercentage =
    severeRiskValue === "N/A" ? 0 : calculatePercentage(severeRiskValue);
  const highRiskPercentage =
    highRiskValue === "N/A" ? 0 : calculatePercentage(highRiskValue);
  const mediumRiskPercentage =
    mediumRiskValue === "N/A" ? 0 : calculatePercentage(mediumRiskValue);
  const lowRiskPercentage =
    lowRiskValue === "N/A" ? 0 : calculatePercentage(lowRiskValue);

  const percentages = [
    severeRiskPercentage,
    highRiskPercentage,
    mediumRiskPercentage,
    lowRiskPercentage,
  ];

  const addressCategories = [
    {
      label: "Number of Severe Risk Addresses",
      value: severeAddressValue,
      percentage: getAddressPercentage(severeAddressValue),
      onClick: () =>
        handleInvolvedAddresses("Severe Risk Addresses", "Severe Risk;"),
    },
    {
      label: "Number of High Risk Addresses",
      value: highAddressValue,
      percentage: getAddressPercentage(highAddressValue),
      onClick: () =>
        handleInvolvedAddresses("High Risk Addresses", "High Risk;"),
    },
    {
      label: "Number of Medium Risk Addresses",
      value: mediumAddressValue,
      percentage: getAddressPercentage(mediumAddressValue),
    },
    {
      label: "Number of Low Risk Addresses",
      value: lowAddressValue,
      percentage: getAddressPercentage(lowAddressValue),
    },
  ];

  const depositCategories = [
    {
      label: "Severe (USD)",
      value: severeRiskValue,
      percentage: calculatePercentage(severeRiskValue),
    },
    {
      label: "High (USD)",
      value: highRiskValue,
      percentage: calculatePercentage(highRiskValue),
    },
    {
      label: "Medium (USD)",
      value: mediumRiskValue,
      percentage: calculatePercentage(mediumRiskValue),
    },
    {
      label: "Low (USD)",
      value: lowRiskValue,
      percentage: calculatePercentage(lowRiskValue),
    },
  ];

  return (
    <Box className={styles.container} gap={"26px"}>
      <div>
        <Title level={2}>Wallet Address Risk Profile</Title>
        <Row gutter={[16, 16]}>
          {addressCategories.map((category) => (
            <Col span={6} key={category.label}>
              {renderCard(category.label, category.value, category.onClick)}
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Title level={2}>Exposure to Risk Breakdown</Title>
        <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
          <Col sm={24} xxl={12}>
            <PercentageBarChart
              percentages={percentages}
              xAxisTitle="Risk Category"
              yAxisTitle="Percentage"
              headerTitle="Percentage of Deposits by Category"
            />
          </Col>
          <Col sm={24} xxl={12}>
            <Box margin={{ top: "48px" }}>
              <ClickableHeader
                forGraph
                title={"Amount of Deposits by Category"}
                onClick={() =>
                  handleExpandDetails(
                    "Exposure to Risk Breakdown",
                    "Exposure to Category Breakdown;"
                  )
                }
              />
            </Box>
            <Row gutter={[0, 24]} style={{ marginBottom: 24, marginTop: 12 }}>
              {depositCategories.map((category) => (
                <Col md={6} xxl={12} key={category.label}>
                  {renderCard(category.label, category.value)}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default RiskProfileAndBreakdown;
