import { createAsyncThunk } from "@reduxjs/toolkit";
import AirtableApiClient from "../../utils/airtableApiClient";
import { MonitoringChartPayload, MonitoringChartRecord } from "./types";

import {
  TransactionDataPayload,
  TransactionDataRecord,
} from "../biWeeklyDeposit/types";

export const getBiWeeklyDepositTrendsDataAction = createAsyncThunk<
  TransactionDataPayload,
  { baseId: string },
  {}
>(
  "biWeeklyDepositTrends/getTransactionMonitoring",
  async ({ baseId }, thunkAPI) => {
    try {
      const client = AirtableApiClient.getInstanceDynamic(baseId);
      const transactionData =
        await AirtableApiClient.fetchAllRecords<TransactionDataRecord>(
          client,
          "Transaction Monitoring?view=Master view&filterByFormula=FIND(%22Bi+Weekly+Trend;%22%2C+%7BPage%7D)"
        );
      return { transactionData };
    } catch (err) {
      if (err instanceof Error) {
        return thunkAPI.rejectWithValue({ error: err.message });
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" });
    }
  }
);

export const getBiWeeklyDepositTrendsMonitoringChartAction = createAsyncThunk<
  MonitoringChartPayload,
  { baseId: string },
  {}
>(
  "biWeeklyDepositTrends/getTransactionMonitoringChart",
  async ({ baseId }, thunkAPI) => {
    try {
      const client = AirtableApiClient.getInstanceDynamic(baseId);
      const monitoringChartData =
        await AirtableApiClient.fetchAllRecords<MonitoringChartRecord>(
          client,
          "Transaction Monitoring Chart?view=Master view&filterByFormula=FIND(%22Bi+Weekly+Trend;%22%2C+%7BPage%7D)"
        );
      return { monitoringChartData };
    } catch (err) {
      if (err instanceof Error) {
        return thunkAPI.rejectWithValue({ error: err.message });
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" });
    }
  }
);
