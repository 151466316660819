import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { Spin, Typography } from "antd";
import { getAlertSummaryDataAction } from "store/alertSummary/alertSummaryActions";
import { AlertOutlined } from "@ant-design/icons";
import { marked } from "marked";
import styles from "./alertSummary.module.scss";
import FinancialTable from "components/financialTable/financialTable";

const { Paragraph, Title } = Typography;

const AlertSummary: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { alertSummaryData, isLoadingAlertSummary } = useSelector(
    (state: RootState) => state.alertSummary
  );

  useEffect(() => {
    if (!id) return;
    dispatch(getAlertSummaryDataAction({ baseId: id }));
  }, [dispatch, id]);

  if (isLoadingAlertSummary) {
    return <Spin size="large" />;
  }

  const columns = [
    {
      title: "Date",
      dataIndex: ["fields", "Date"],
      key: "date",
    },
    {
      title: "VASPs",
      dataIndex: ["fields", "VASPs"],
      key: "vasps",
    },
    {
      title: "Page",
      key: "page",
      render: (_: any, record: any) => (
        <span>
          <AlertOutlined style={{ marginRight: 8, color: "red" }} />
          {record.fields.Page}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: ["fields", "Category"],
      key: "category",
    },
    {
      title: "Details",
      key: "details",
      render: (_: any, record: any) => (
        <Paragraph>
          {record.fields["Alert Message"] ? (
            <span
              dangerouslySetInnerHTML={{
                __html: marked.parseInline(
                  record.fields["Alert Message"].replace(/\n/g, "<br />")
                ) as string,
              }}
            />
          ) : (
            "N/A"
          )}
        </Paragraph>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Title level={2}>Alert Summary</Title>
      <FinancialTable<any>
        data={alertSummaryData.filter((item) => item.fields["Page"])}
        columns={columns}
      />
    </div>
  );
};

export default AlertSummary;
