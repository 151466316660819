import React from "react";
import { Typography } from "antd";
import styles from "./categoryBreakdown.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "store";
import FinancialTable from "components/financialTable/financialTable";
import { severityOrder } from "utils/constants";
const { Title } = Typography;

const CategoryBreakdown: React.FC = () => {
  const data = useSelector((state: RootState) => state.monthlyDeposit.data);
  const breakdownData = data.filter((item) =>
    item.fields.Page.includes("Deposit Exposure to Category Breakdown;")
  );

  const columns = [
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
    },
    {
      title: "Category",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "%",
      dataIndex: "percentage",
      key: "percentage",
      render: (text: string | undefined) => text || "-",
    },
    {
      title: "Volume (USD)",
      dataIndex: "volume",
      key: "volume",
      render: (text: string | undefined) => text || "-",
    },
  ];

  const totalVolume = breakdownData.reduce(
    (acc, curVal) => acc + (curVal.fields.Value || 0),
    0
  );

  const dataSource = breakdownData
    .sort((a, b) => {
      const severityA =
        severityOrder[a.fields.Severity as keyof typeof severityOrder];
      const severityB =
        severityOrder[b.fields.Severity as keyof typeof severityOrder];
      if (severityA !== severityB) {
        return severityA - severityB;
      }
      return (a.fields.Order || 0) - (b.fields.Order || 0);
    })
    .map((item) => {
      return {
        severity: item.fields.Severity,
        key: item.fields.Key,
        percentage:
          item.fields.Value !== undefined && item.fields.Value !== 0
            ? ((item.fields.Value / totalVolume) * 100).toFixed(2) + "%"
            : "-",
        volume:
          item.fields.Value !== undefined
            ? item.fields.Value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : undefined,
      };
    });

  return (
    <div className={styles.container}>
      <Title level={2}>Deposit Exposure to Category Breakdown</Title>
      <FinancialTable<any> data={dataSource} columns={columns} />
    </div>
  );
};

export default CategoryBreakdown;
