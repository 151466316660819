import { createAsyncThunk } from "@reduxjs/toolkit";
import { StandardSupervisionPayload, StandardSupervisionRecord } from "./types";
import AirtableApiClient from "utils/airtableApiClient";

export const getStandardSupervisionData = createAsyncThunk<
  StandardSupervisionPayload,
  { baseId: string },
  {}
>("standardSupervision/get", async ({ baseId }, thunkAPI) => {
  try {
    const client = AirtableApiClient.getInstanceDynamic(baseId);
    const supervisionData =
      await AirtableApiClient.fetchAllRecords<StandardSupervisionRecord>(
        client,
        "Supervision%20Table?view=Master%20view"
      );
    return { records: supervisionData };
  } catch (err) {
    if (err instanceof Error) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return thunkAPI.rejectWithValue({ error: "Unknown error" });
  }
});
