import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { RootState, useAppDispatch } from "store";
import {
  getBiWeeklyDepositTrendsDataAction,
  getBiWeeklyDepositTrendsMonitoringChartAction,
} from "store/biWeeklyDepositTrends/biWeeklyDepositTrendsActions";
import { useParams } from "react-router-dom";
import Overview from "./overview";
import { DetailsModal } from "components/biWeeklyDepositModals";
import { TransactionDataRecord } from "store/biWeeklyDeposit/types";
import RiskProfile from "./riskProfile";
import ExposureToRiskBreakdown from "./exposureToRiskBreakdown";
import TransactionLevelAnalysis from "./transactionLevelAnalysis";
import DirectRiskyTransferAnalysis from "./directRiskyTransferAnalysis";
import IndirectRiskyTransferAnalysis from "./indirectRiskyTransferAnalysis";
import { Box } from "grommet";

const BiWeeklyDepositTrends: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState<
    TransactionDataRecord[]
  >([]);

  const [detailsModalTitle, setDetailsModalTitle] = useState("");

  useEffect(() => {
    if (!id) return;
    dispatch(getBiWeeklyDepositTrendsDataAction({ baseId: id }));
    dispatch(getBiWeeklyDepositTrendsMonitoringChartAction({ baseId: id }));
  }, [dispatch, id]);

  const {
    isLoadingBiWeeklyDepositTrends,
    isLoadingMonitoringChart,
    error,
    transactionData,
  } = useSelector((state: RootState) => state.biWeeklyDepositTrends);

  const handleExpandDetails = (title: string, filterKey: string) => {
    const filteredData =
      transactionData?.transactionData.filter((record) =>
        record.fields.Page.includes(filterKey)
      ) || [];
    setDetailsModalData(filteredData);
    setDetailsModalTitle(title);
    setIsDetailsModalVisible(true);
  };

  if (isLoadingBiWeeklyDepositTrends || isLoadingMonitoringChart) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box gap="26px">
      <Overview />
      <RiskProfile />
      <ExposureToRiskBreakdown />
      <TransactionLevelAnalysis />
      <DirectRiskyTransferAnalysis handleExpandDetails={handleExpandDetails} />
      <IndirectRiskyTransferAnalysis
        handleExpandDetails={handleExpandDetails}
      />
      <DetailsModal
        isVisible={isDetailsModalVisible}
        onClose={() => setIsDetailsModalVisible(false)}
        data={detailsModalData}
        title={detailsModalTitle}
      />
    </Box>
  );
};

export default BiWeeklyDepositTrends;
