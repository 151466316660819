import React from "react";
import { Typography, Row, Col } from "antd";
import { ProviderFields } from "store/providers/types";
import styles from "./bankInformation.module.scss";

const { Title, Text } = Typography;

interface BankInformationProps {
  provider: ProviderFields;
}

const BankInformation: React.FC<BankInformationProps> = ({ provider }) => {
  return (
    <div className={styles.container}>
      <Title level={2}>Bank Information</Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Title level={5}>Operational Bank Account</Title>
          <Text>{provider["Operational Bank Account"] || "-"}</Text>
        </Col>{" "}
        <Col xs={24} md={8}>
          <Title level={5}>Client Money Account</Title>
          <Text>{provider["Client Money Account"] || "-"}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default BankInformation;
