import { useEffect, useRef } from "react";

interface IContainer {
  contains: (element: any) => boolean;
}

const useClickOutside = <T extends IContainer>(fn: (e: globalThis.MouseEvent) => void) => {
  const containerRef = useRef<T | null>(null);
  useEffect(() => {
    if (!containerRef.current) return;

    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        fn(event);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fn]);
  return containerRef;
};

export default useClickOutside;
