import React from "react";
import { Card, Typography } from "antd";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ClickableHeader from "../clickableHeader/clickableHeader"; // Adjust the path to your custom component
import styles from "./percentageBarChart.module.scss"; // Adjust the path to your CSS module

const { Title } = Typography;

interface PercentageBarChartProps {
  percentages: number[];
  xAxisTitle: string;
  yAxisTitle: string;
  headerTitle: string;
  onHeaderClick?: () => void; // Optional click handler
}

const PercentageBarChart: React.FC<PercentageBarChartProps> = ({
  percentages,
  xAxisTitle,
  yAxisTitle,
  headerTitle,
  onHeaderClick,
}) => {
  const chartData = {
    labels: ["Severe", "High", "Medium", "Low"],
    datasets: [
      {
        label: "Deposit Exposure",
        data: percentages.map((p) => (p > 0 && p < 1 ? 1 : p)),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    barPercentage: 0.7,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const realValue = percentages[context.dataIndex];
            if (realValue < 0.01) {
              return "<0.01%";
            }
            if (realValue > 99.99) {
              return ">99.99%";
            }
            return `${realValue.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: any) => `${value}%`,
          stepSize: 20,
        },
        title: {
          display: true,
          text: yAxisTitle,
        },
      },
      x: {
        title: {
          display: true,
          text: xAxisTitle,
        },
      },
    },
  };

  return (
    <Card className={styles.innerCard}>
      <div className={styles.chartHeader}>
        {onHeaderClick ? (
          <ClickableHeader
            forGraph
            title={headerTitle}
            onClick={onHeaderClick}
          />
        ) : (
          <Title level={4}>{headerTitle}</Title>
        )}
      </div>
      <Bar data={chartData} options={options} />
    </Card>
  );
};

export default PercentageBarChart;
