import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { RootState, AppDispatch } from "store";
import {
  getMonthlyDepositDataAction,
  getMonthlyDepositInvolvedAddressesAction,
} from "store/monthlyDeposit/monthlyDepositActions";
import Overview from "./overview";
import RiskProfile from "./rskProfile";
import RiskBreakdown from "./riskBreakdown";
import CategoryBreakdown from "./categoryBreakdown";
import { useParams } from "react-router-dom";
import { Box } from "grommet";

const MonthlyDeposit: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const { isLoadingTransaction, isLoadingInvolvedAddresses } = useSelector(
    (state: RootState) => state.monthlyDeposit
  );

  useEffect(() => {
    if (!id) return;
    dispatch(getMonthlyDepositDataAction({ baseId: id }));
    dispatch(getMonthlyDepositInvolvedAddressesAction({ baseId: id }));
  }, [dispatch, id]);

  if (isLoadingTransaction || isLoadingInvolvedAddresses) {
    return <Spin size="large" />;
  }

  return (
    <Box gap="26px">
      <Overview />
      <RiskProfile />
      <RiskBreakdown />
      <CategoryBreakdown />
    </Box>
  );
};

export default MonthlyDeposit;
