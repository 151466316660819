import React from "react";
import { Row, Col, Card, Typography, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import FinancialChart from "components/financialChart/financialChart";
import { SupervisionOverviewRecord } from "store/supervisionOverview/types";
import styles from "./financialSummary.module.scss";
import { Box } from "grommet";
import PercentageChange from "components/percentageChange/percentageChange";

const { Title, Text } = Typography;

const FinancialSummary: React.FC = () => {
  const financialSoundness = useSelector((state: RootState) =>
    state.supervisionOverview.supervisionOverview.filter(
      (record) =>
        record.fields.Section &&
        record.fields.Section.includes("Financial Soundness")
    )
  );
  const totalPaidUpCapital = useSelector((state: RootState) =>
    state.supervisionOverview.supervisionOverview.find(
      (item) => item.fields.Key === "Total Paid Up Capital"
    )
  );

  const summaryData = financialSoundness
    .sort((a, b) => {
      return (a.fields.Order || 0) - (b.fields.Order || 0);
    })
    .map((record) => {
      const values = record.fields.Value
        ? record.fields.Value.split(";").map(Number)
        : [];
      const latestValue = values.length > 0 ? values[0] : 0;
      const prevValue = values.length > 0 ? values[1] : 0;
      const totalValue = record.fields["Total Amount"] || 0;
      const percentageChange =
        prevValue !== 0 && latestValue !== 0
          ? ((latestValue - prevValue) / Math.abs(prevValue)) * 100
          : undefined;

      return {
        id: record.id,
        label: record.fields.Key,
        latestValue: latestValue.toLocaleString(),
        totalValue: Number(totalValue).toLocaleString(),
        percentageChange,
        percentageColor: percentageChange
          ? Number(percentageChange) > 0
            ? "#0db977"
            : "red"
          : undefined,
        isAlert: record.fields.isAlert,
        alertMessage: record.fields["Alert Message"],
        values,
        lastUpdateMonth: record.fields["Last Update Month"],
      };
    });

  const getChartData = (record: SupervisionOverviewRecord) => {
    const valueArray =
      record.fields.Value?.toString().split(";").map(Number) || [];
    const lastUpdateMonth = record.fields["Last Update Month"]!;
    return { valueArray, lastUpdateMonth };
  };
  const alertCount = summaryData.filter((item) => item.isAlert).length;
  return (
    <div className={styles.container}>
      <Box direction="row" justify="end">
        <div className={alertCount ? styles.alert : styles.noAlert}>
          Alerts on this page: {alertCount}
        </div>
      </Box>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Box margin={{ bottom: "36px", top: "24px" }}>
                <Box>
                  <Title level={2}>Total Paid Up Capital (AED)</Title>
                  <Title level={5}>held in the name of VARA</Title>
                </Box>
                <Title level={1}>
                  {totalPaidUpCapital?.fields.Value
                    ? `$${Number(
                        totalPaidUpCapital?.fields.Value
                      ).toLocaleString()}`
                    : "N/A"}
                </Title>
              </Box>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {summaryData.map((item) => {
          const currentRecord =
            financialSoundness.find((record) => record.id === item.id) || null;
          return (
            <Col xs={24} xl={12} key={item.id}>
              <Card className={styles.innerCard}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 38,
                    fontWeight: 600,
                    marginBottom: 24,
                  }}
                >
                  {item.label}
                  {item.isAlert && (
                    <Tooltip title={item.alertMessage}>
                      <ExclamationCircleOutlined
                        style={{ color: "red", marginLeft: "8px" }}
                      />
                    </Tooltip>
                  )}
                </div>
                <Box direction="row" margin={{ bottom: "20px" }}>
                  <Box flex>
                    <Title level={5} style={{ textAlign: "left" }}>
                      As of {item.lastUpdateMonth} {new Date().getFullYear()}{" "}
                      (AED)
                    </Title>
                    <Text style={{ textAlign: "left", display: "block" }}>
                      {currentRecord?.fields.Value
                        ? `$${Number(
                            currentRecord?.fields.Value.split(";")
                              .reduce((acc, cur) => acc + Number(cur), 0)
                              .toFixed(2)
                          ).toLocaleString()}`
                        : "N/A"}
                    </Text>
                  </Box>{" "}
                  <Box flex>
                    <Title level={5} style={{ textAlign: "left" }}>
                      {item.lastUpdateMonth} {new Date().getFullYear()} (AED)
                    </Title>
                    <Box direction="row" align="center" gap={"16px"}>
                      <Text
                        style={{
                          textAlign: "left",
                          display: "block",
                        }}
                      >
                        {item.latestValue}{" "}
                      </Text>
                      <PercentageChange
                        percentageChange={item.percentageChange}
                      />
                    </Box>
                  </Box>
                </Box>
                {currentRecord && (
                  <FinancialChart
                    chartData={getChartData(currentRecord).valueArray}
                    lastUpdateMonth={
                      getChartData(currentRecord).lastUpdateMonth
                    }
                    label={`${currentRecord.fields.Key}`}
                    showLegend
                  />
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default FinancialSummary;
