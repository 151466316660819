import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Card, Row, Col, Spin, Typography } from "antd";
import FinancialChart from "components/financialChart/financialChart";
import { MonitoringChartRecord } from "store/biWeeklyDepositTrends/types";
import FinancialStatistic from "components/financialStatistic/financialStatistic";
import styles from "./indirectRiskyTransferAnalysis.module.scss";
import ClickableHeader from "components/clickableHeader/clickableHeader";
import { Box } from "grommet";

const { Title } = Typography;

interface IndirectRiskyTransferAnalysisProps {
  handleExpandDetails: (title: string, filterKey: string) => void;
}

const IndirectRiskyTransferAnalysis: React.FC<
  IndirectRiskyTransferAnalysisProps
> = ({ handleExpandDetails }) => {
  const {
    transactionData,
    monitoringChartData,
    isLoadingBiWeeklyDepositTrends,
  } = useSelector((state: RootState) => state.biWeeklyDepositTrends);

  const getChartData = () => {
    const chartData: MonitoringChartRecord | undefined =
      monitoringChartData?.monitoringChartData.find(
        (item) =>
          item.fields.Name === "Volume of Indirect Risky Transfer" &&
          item.fields.Page.includes("Volume of Indirect Risky Transfer;")
      );

    if (!chartData) {
      return null;
    }

    const valueArray =
      chartData.fields.Value?.toString().split(";").map(Number) || [];
    const lastUpdateMonth = chartData.fields["Last Update Month"];

    return { valueArray, lastUpdateMonth };
  };

  if (isLoadingBiWeeklyDepositTrends) {
    return <Spin size="large" />;
  }

  const chartData = getChartData();

  const getValue = (key: string) => {
    const target = transactionData?.transactionData.find(
      (item) => item.fields.Key === key
    );
    return target?.fields.Value;
  };

  return (
    <div className={styles.container}>
      <Title level={2}>
        Transaction Level Analysis (BTC & ETH) - Indirect Risky Transfer Change
      </Title>
      <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
        <Col sm={24} xl={14}>
          <Card className={styles.innerCard}>
            <Title level={4}>Volume of Indirect Risky Transfer</Title>
            {chartData && (
              <FinancialChart
                chartData={chartData.valueArray}
                lastUpdateMonth={chartData.lastUpdateMonth}
                label="Volume of Indirect Risky Transfer"
              />
            )}
          </Card>
        </Col>
        <Col sm={24} xl={10}>
          <Row>
            <Col>
              <Box margin={{ top: "48px" }}>
                <Title level={4} style={{ margin: 0 }}>
                  Bi-weekly Indirect Risky Transfer Change
                </Title>
              </Box>
              <ClickableHeader
                title={"Indirect Risky Transfer In The Past Two Weeks"}
                onClick={() =>
                  handleExpandDetails(
                    "Indirect Risky Transfer In The Past Two Weeks",
                    "Indirect Risk Transfer Exposure to Category Breakdown;"
                  )
                }
              />
              <FinancialStatistic
                value={getValue(
                  "Indirect Risky Transfer In The Past Two Weeks"
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IndirectRiskyTransferAnalysis;
