import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Card, Row, Col, Spin, Typography, Button, Tooltip } from "antd";
import FinancialChart from "components/financialChart/financialChart";
import { TransactionChartDataRecord } from "store/monthlyDepositTrends/types";
import FinancialStatistic from "components/financialStatistic/financialStatistic";
import styles from "./monthlyDepositTrends.module.scss";
import { Box } from "grommet";

const { Title } = Typography;

const OverviewAndRiskProfile: React.FC = () => {
  const {
    monthlyDepositTrends,
    monthlyDepositCharts,
    isLoadingMonthlyDepositTrends,
    isLoadingDepositExposureCategoryBreakdown,
    isLoadingMonthlyDepositCharts,
  } = useSelector((state: RootState) => state.monthlyDepositTrends);

  const [selectedChart, setSelectedChart] = useState<string>("Severe Risk");

  const getChartData = () => {
    const chartData: TransactionChartDataRecord | undefined =
      monthlyDepositCharts.find(
        (item) =>
          item.fields.Name === selectedChart &&
          item.fields.Page.includes(
            "Number of Associated Wallet Address Per Severity Level;"
          )
      );

    if (!chartData) {
      return null;
    }

    const valueArray =
      chartData.fields.Value?.toString().split(";").map(Number) || [];
    const lastUpdateMonth = chartData.fields["Last Update Month"];

    return { valueArray, lastUpdateMonth };
  };

  if (
    isLoadingMonthlyDepositTrends ||
    isLoadingDepositExposureCategoryBreakdown ||
    isLoadingMonthlyDepositCharts
  ) {
    return <Spin size="large" />;
  }

  const chartData = getChartData();

  const getValue = (key: string) => {
    const target = monthlyDepositTrends?.find(
      (item) => item.fields.Key === key
    );
    return target?.fields.Value;
  };

  const ListedTokenRow = monthlyDepositTrends.find(
    (record) => record.fields.Key === "Listed Token"
  );

  return (
    <Box className={styles.container}>
      <Box align="end">
        <div>
          {ListedTokenRow?.fields.Value
            ? `${ListedTokenRow?.fields.Value}% of tokens are covered.`
            : " "}{" "}
          {ListedTokenRow?.fields.Value && (
            <Tooltip title={ListedTokenRow?.fields.List?.replaceAll(";", ", ")}>
              <u>List of unsupported tokens</u>
            </Tooltip>
          )}
        </div>
      </Box>
      <div>
        <Title level={2}>Summary</Title>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col span={8}>
            <Title level={5}>VASP Monthly Deposit Volume Change</Title>
            <FinancialStatistic value={getValue("VASP Deposit Volume")} />
          </Col>
          <Col span={8}>
            <Title level={5}>Total No. of Users Monthly Change</Title>
            <FinancialStatistic value={getValue("Total Number of Users")} />
          </Col>
          <Col span={8}>
            <Title level={5}>
              Total No. of Wallet Addresses Monthly Change
            </Title>
            <FinancialStatistic
              value={getValue("Total Number of Wallet Addresses")}
            />
          </Col>
        </Row>
      </div>
      <div>
        <Title level={2}>Wallet Address Risk Profile</Title>
        <Row gutter={[38, 16]} style={{ marginTop: 12 }}>
          <Col xl={24} xxl={12}>
            <Card className={styles.innerCard}>
              <div className={styles.chartHeader}>
                <Title level={4}>No. of {selectedChart} Address</Title>
                <Box direction="row" gap={"8px"}>
                  {monthlyDepositCharts
                    .filter((item) =>
                      item.fields.Page.includes(
                        "Number of Associated Wallet Address Per Severity Level;"
                      )
                    )
                    .map((item) => (
                      <Button
                        key={item.fields.Name}
                        className={`${styles.graphTab} ${
                          selectedChart === item.fields.Name && styles.selected
                        }`}
                        onClick={() => setSelectedChart(item.fields.Name)}
                      >
                        {item.fields.Name}
                      </Button>
                    ))}
                </Box>
              </div>
              {chartData && (
                <FinancialChart
                  chartData={chartData.valueArray}
                  lastUpdateMonth={chartData.lastUpdateMonth!}
                  label={`No. of ${selectedChart} Addresses`}
                  showIntegersOnly
                />
              )}
            </Card>
          </Col>
          <Col sm={24} xxl={12}>
            <Box margin={{ top: "48px" }}>
              <Title level={4}>
                No. of Associated Wallet Address Per Severity Level
              </Title>
            </Box>
            <Row gutter={[0, 24]}>
              {[
                {
                  label: "Severe Risk Addresses",
                  key: "Number of Severe Risk Addresses",
                },
                {
                  label: "High Risk Addresses",
                  key: "Number of High Risk Addresses",
                },
                {
                  label: "Medium Risk Addresses",
                  key: "Number of Medium Risk Addresses",
                },
                {
                  label: "Low Risk Addresses",
                  key: "Number of Low Risk Addresses",
                },
              ].map(({ label, key }) => (
                <Col md={6} xxl={12} key={key}>
                  <Title level={5}>{label}</Title>
                  <FinancialStatistic value={getValue(key)} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default OverviewAndRiskProfile;
